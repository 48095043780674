@use "../../../styles/common.scss" as *;
@use "./index.scss" as *;

// PrimaryLight Button
.Button {
  @include button-general();
  @include button-size(155px);
  @include flex-center();

  &.Button__primary--light {
    color: $theme-text-color-19;
    background-color: $theme-background-color-19;

    @include button-actions(
      $theme-background-color-18,
      $theme-background-color-17,
      $theme-text-color-19
    );
  }

  @include button-loading(
    $theme-border-color-14,
    fade($theme-border-color-14, 30%)
  );
}
