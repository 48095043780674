/* z-index */
$z-index-navigation: 200;
$z-index-navigation-action: 300;
$z-index-header-shop-list: 100;
$z-index-header: 200;
$z-index-header-search: 100;
$z-index-achievement-list: 100;
$z-index-campaign-template: 100;
$z-index-fail-screen: 50;
$z-index-date-picker: 100;
$z-index-spinner: 50;
$z-index-search-box: 20;
$z-index-search-list: 200;
$z-index-action-list: 30;
$z-index-modal: 1200;
$z-index-modal-wrapper: 1300;
$z-index-modal-close: 1400;
$z-index-backdrop: 1500;
$z-index-message-container: 200;
$z-index-message-container-sm: 180;
$z-index-customer-profile: 500;
$z-index-customer-profile-xs: 10;
$z-index-customer-profile-sm: 10;
$z-index-customer-communication: 100;
$z-index-review-filters: 1000;
$z-index-review-filters-title: 1100;
$z-index-date-picker: 100;
$z-index-appointment-list: 100;
$z-index-activity-filter-popup: 100;
$z-index-customer-filters-list: 100;
$z-index-countries-list: 100;
