@use "../../../styles/common.scss" as *;

.tooltipOrderButton {
  min-width: 190px !important;
  max-width: 296px !important;
}

.firstStep {
  display: flex;
  gap: 40px;
}

.halfWrapper {
  width: 47%;

  &:nth-child(2) {
    width: 53%;
    background: url("../../../assets/images/devices-modal-bg-d.png");
    background-size: cover;
    border-radius: 30px;
    padding: 24px;
  }
}

.modalTitle {
  color: $theme-text-color-0;
  font: normal 700 24px/32px Lato;
  margin-bottom: 8px;
}

.modalDescription {
  color: $theme-text-color-2;
  font: normal 400 16px/24px Lato;
  margin-bottom: 32px;
  max-width: 630px;
}

.divider {
  width: 100%;
  margin: 24px 0;
  border-top: 1px solid #d9dfe8;

  &.noSpace {
    margin: 0;
    border-top: 1px solid #ebeff4;
  }
}

.inputTitle {
  color: $theme-text-color-0;
  font: normal 700 18px/24px Lato;
  margin-bottom: 24px;
}

.selectLabel {
  color: $theme-text-color-2;
  font: normal 600 14px/16px Lato;
}

.cityInputLabel {
  display: block;
  margin: -3px 0 8px;
  font: normal 600 14px / 14px Lato;
}
.shopItem {
  display: flex;
  align-items: baseline;
  gap: 4px;

  .shopItemName {
    color: $theme-text-color-1;
    font: normal 600 14px/16px Lato;
  }

  .shopItemDot {
    color: #95a1b2;
  }

  .shopItemAddress {
    color: $theme-text-color-2;
    font: normal 400 12px/16px Lato;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.menuItem {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    .shopItemAddress {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
}

.pricingDetails {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .textWrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    .detailsTitle {
      color: $theme-text-color-0;
      font: normal 700 18px/24px Lato;
    }
  }

  .detailsDescription {
    color: $theme-text-color-1;
    font: normal 400 16px/24px Lato;
  }

  &:last-child {
    gap: 4px;

    .detailsDescription {
      &:nth-child(2) {
        margin-top: 4px;
      }
    }
  }
}

.info {
  font: normal 700 30px/40px Lato;
}

.highlighted {
  color: $theme-text-color-0;
  font-weight: 700;
}

.arrowBack,
.arrowNext {
  width: 14px;
  height: 9.634px;
  transform-origin: center;
  flex-shrink: 0;
}

.arrowNext {
  transform: rotate(180deg);
  g path {
    fill: #ffffff;
  }
}

.secondStep,
.thirdStep {
  display: flex;
  flex-direction: column;
}

.formWrapper {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;

  > * {
    flex: 0 0 31.5%;
    margin-bottom: 0;
  }
}

.thirdStep {
  gap: 24px;
}

.successHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .successDescription {
    text-align: center;
    margin-bottom: 0px;
  }
}

.totalWrapper {
  background: #ffffff;
  border-radius: 8px;

  &:last-child {
    margin-bottom: 12px;
  }

  .totalLabel {
    padding: 16px 24px;
    color: $theme-text-color-0;
    font: normal 700 20px/28px Lato;
  }

  .totalInfo {
    padding: 24px;
    display: flex;

    .totalCard {
      width: 50%;
      display: flex;
      gap: 16px;
      align-items: center;

      &:first-child {
        padding-right: 24px;
        border-right: 1px solid $theme-border-color-4;
      }
      &:last-child {
        padding-left: 24px;
      }

      .totalCardInfo {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .totalCardTitle {
          color: $theme-text-color-2;
          font: normal 400 16px/20px Lato;
        }

        .totalCardNumber {
          color: $theme-text-color-0;
          font: normal 700 24px/32px Lato;
        }
      }
    }
  }

  .deliveryInfo {
    padding: 24px;
    display: flex;
    gap: 24px;

    .summary {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .summaryItem {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .summaryItemTitle {
          color: $theme-text-color-2;
          font: normal 400 14px/16px Lato;
        }

        .summaryItemValue {
          color: $theme-text-color-0;
          font: normal 700 16px/20px Lato;
        }
      }
    }
  }
}

.errorAlert {
  display: flex;
  align-items: center;
  padding: 14px 16px;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid $theme-border-color-15;
  background: $theme-background-color-45;
  color: $theme-text-color-23;
  font: 600 14px/20px Lato;
  margin: 0 auto 0 0;

  svg {
    path {
      fill: $theme-fill-color-16;
    }
  }
}

@media screen and (max-width: ($md-1 - 1px)) {
  .halfWrapper {
    width: 44%;

    &:nth-child(2) {
      width: 56%;
      background: url("../../../assets/images/devices-modal-bg-t.png");
      background-size: cover;
    }
  }

  .formWrapper {
    > * {
      flex: 0 0 30.9%;
      margin-bottom: 0;
    }

    &:nth-child(-n + 4) {
      > * {
        &:nth-last-child(-n + 2) {
          flex: 0 0 48.1%;
        }
      }
    }
  }
}

@media screen and (max-width: ($sm - 1px)) {
  .firstStep {
    flex-wrap: wrap;
    gap: 32px;
  }

  .halfWrapper {
    width: 100%;

    &:nth-child(2) {
      width: 100%;
      background: url("../../../assets/images/devices-modal-bg-m.png");
      background-size: cover;
    }
  }

  .formWrapper {
    > * {
      flex: 0 0 100%;
      margin-bottom: 0;
    }

    &:nth-child(-n + 4) {
      > * {
        &:nth-last-child(-n + 2) {
          flex: 0 0 100%;
        }
      }
    }
  }

  .totalWrapper {
    .totalInfo {
      flex-wrap: wrap;

      .totalCard {
        width: 100%;

        &:first-child {
          padding-right: 0px;
          border-right: none;
          border-bottom: 1px solid $theme-border-color-4;
          padding-bottom: 16px;
        }

        &:last-child {
          padding-left: 0px;
          padding-top: 16px;
        }
      }
    }

    .deliveryInfo {
      flex-wrap: wrap;

      .summary {
        width: 100%;
      }
    }
  }

  .errorAlert {
    margin-right: 0px;
    width: 100%;
    margin-bottom: 24px;
  }
}
