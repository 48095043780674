/* Margin auto */
.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

/* Margin y (top + bottom) */
$margin-y: 10, 20, 30, 40;

@each $mar in $margin-y {
  .my-#{$mar} {
    margin-top: $mar + 0px;
    margin-bottom: $mar + 0px;
  }
}

/* Margin x (right + left) */
$margin-x: 8, 10, 20, 30, 40;

@each $mar in $margin-x {
  .mx-#{$mar} {
    margin-right: $mar + 0px;
    margin-left: $mar + 0px;
  }
}

// /* Margin top */
$margin-top: 0, 6, 7, 10, 15, 16, 20, 40;

@each $mar in $margin-top {
  .mt-#{$mar} {
    margin-top: $mar + 0px;
  }
}

// /* Margin right */
$margin-right: 4, 5, 10, 15, 16, 20, 40;

@each $mar in $margin-right {
  .mr-#{$mar} {
    margin-right: $mar + 0px !important;
  }
}

// /* Margin bottom */
$margin-bottom: 4, 6, 8, 10, 12, 16, 20, 22, 24;

@each $mar in $margin-bottom {
  .mb-#{$mar} {
    margin-bottom: $mar + 0px;
  }
}

// /* Margin left */
$margin-left: 4, 5, 6, 7, 8, 10, 20;

@each $mar in $margin-left {
  .ml-#{$mar} {
    margin-left: $mar + 0px !important;
  }
}
