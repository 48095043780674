@use "../../styles/common.scss" as *;

.popper {
  z-index: 2000;
}

.content {
  box-shadow: 2px 10px 50px #3f4b8021;
}

.popper[data-popper-placement^="bottom"] {
  padding-top: 10px;
}

.popper[data-popper-placement^="top"] {
  padding-bottom: 10px;
}

.popper[data-popper-placement^="right"] {
  padding-left: 10px;
}
