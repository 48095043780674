@use "../../../../styles/common.scss" as *;

.shopWrapper {
  overflow: hidden;
}

.shopAddressWrapper {
  overflow: hidden;
}

.shopAddress {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: $theme-text-color-2;
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
}

@media screen and (max-width: $sm - 1px) {
  .shopWrapper {
    max-width: 50%;
  }

  .shopAddressWrapper {
    padding-top: 4px;
  }

  .shopAddress {
    margin-top: 0;
  }
}
