@use "../../styles/common" as *;

.Wrapper {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/images/login-background-2560.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.FormSection {
  display: flex;
  flex-direction: column;
  width: 550px;
  min-height: 752px;
  background-color: $theme-background-color-10;
  padding: 40px 64px;
  border-radius: 8px;

  .TextMain {
    color: $theme-text-color-0;
    font-size: 36px;
    font-weight: 700;
    margin: 0 auto;
    user-select: none;
  }

  .Text {
    color: $theme-text-color-1;
    font-size: 16px;
    margin: 4px auto 35px;
    user-select: none;
  }

  .TextSmall {
    color: $theme-text-color-2;
    font-size: 12px;
    margin: auto auto 0;
    user-select: none;
  }

  .InputLabel {
    text-align: left;
    font: normal normal 600 14px/36px Lato;
    letter-spacing: 0;
    color: $theme-text-color-2;
    margin: -4px 0;
  }

  .Input {
    padding: 6px 15px !important;
    border-radius: 6px;

    &:hover,
    &:focus,
    &:has(~ *:hover) {
      padding: 6px 14px !important;
      border: 2px solid $theme-border-color-14 !important;
    }

    &.password {
      padding: 6px 44px 6px 15px !important;

      &:hover,
      &:focus,
      &:has(~ *:hover) {
        padding: 6px 43px 6px 14px !important;
      }
    }
  }

  .SubmitButton {
    border: none;
    border-radius: 6px;
    background: $theme-background-color-28;
    color: $theme-text-color-6;
    width: 100%;
    height: 44px;
    font: normal normal bold 14px/24px Lato;
    outline: none;
    margin: 24px 0 0;
    user-select: none;

    &.Active {
      &:hover {
        background-color: $theme-background-color-27;
      }
      &:focus {
        outline: none;
      }
      &:active {
        background-color: $theme-background-color-26;
      }
    }

    &.Inactive {
      background: $theme-background-color-7;
      color: $theme-text-color-4;
      cursor: default;
    }

    .loader {
      width: 16px;
      height: 16px;
      margin: auto;
      background-image: url(../../assets/icons/loader-white.svg);
      animation: spin 1s linear infinite;
    }
  }
  .PasswordEye,
  .PasswordEyeClosed {
    float: right;
    position: relative;
    right: 16px;
    bottom: 28px;

    &:hover {
      cursor: pointer;
      path,
      circle {
        fill: $theme-fill-color-12;
      }
    }
  }

  .PasswordEyeClosed {
    bottom: 30px;
  }
}

.logo {
  width: 262px;
  height: 319px;
  background-image: url(../../assets/icons/steermatics-logo.svg);
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 100px 0 350px;
}

@media screen and (max-width: $xxl - 1px) {
  .Wrapper {
    background-image: url(../../assets/images/login-background-1920.png);
  }

  .logo {
    margin: 0 140px 0 310px;
  }
}

@media screen and (max-width: $xl - 1px) {
  .Wrapper {
    background-image: url(../../assets/images/login-background-1600.png);
  }
}

@media screen and (max-width: $lg-1 - 1px) {
  .Wrapper {
    background-image: url(../../assets/images/login-background-1200.png);
  }

  .logo {
    margin: 0 158px 0 182px;
  }
}

@media screen and (max-width: $lg - 1px) {
  .Wrapper {
    background-image: url(../../assets/images/login-background-1024.png);
  }

  .logo {
    margin: 0 70px 0 94px;
  }
}

@media screen and (max-width: $md-1 - 1px) {
  .Wrapper {
    background-image: url(../../assets/images/login-background-768.png);
  }

  .logo {
    display: none;
  }

  .logoMobile {
    width: 125px;
    height: 108px;
    margin: 0 auto 38px;
    background-image: url(../../assets/icons/steermatics-logo-mobile.svg);
  }
}

@media screen and (min-width: $sm) {
  .FormSection {
    height: auto !important;
  }
}

@media screen and (max-width: $sm - 1) {
  .Wrapper {
    background-image: none;
  }
  .FormSection {
    height: 100vh;
    width: 100vw;
    padding: 24px 16px;
    min-height: 500px;
    position: absolute;
    top: 0;

    .TextMain {
      margin-top: 0;
      margin-bottom: 12px;
      font-size: 24px;
      text-align: center;
      line-height: normal;
    }

    .Text {
      margin-top: 0;
    }
  }

  .logoMobile {
    width: 79px;
    height: 68px;
    margin: 0 auto 27px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
