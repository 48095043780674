@use "../../styles/common.scss" as *;

.Row {
  margin-left: -12px;
  margin-right: -12px;
}

.VehiclesPanelContainer {
  padding-top: 24px;

  &:has(.EmptyVehicles) {
    padding-top: 0;
  }
}

.VehicleInfoPanel {
  height: max-content;
  width: 100%;
  background-color: white;
  box-shadow: 0px 7px 13px #073d7d07;
  border-radius: 7px;
  padding: 24px;
  margin-bottom: 24px;

  &__headerLabel {
    font-weight: 700;
    color: #191919;
    font-size: 18px;
  }

  &__headerLabelCounter {
    font-weight: 400;
    color: $theme-text-color-4;
  }
}

.WarningIcon {
  min-width: 18px;
  min-height: 16px;
}

.SteerContainer {
  padding-top: 15px !important;
}

.Container {
  max-width: 1406px;
  padding: 24px 40px !important;
}

@media screen and (max-width: 1599px) {
  .Container {
    padding: 24px !important;
  }
}

@media screen and (min-width: 1600px) {
  .Col {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 1023px) {
  .MobileContainer {
    padding: 24px 30px 10px !important;
  }

  .SteerContainer {
    padding-top: 15px !important;
  }

  .MobileWrapper {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 767px) {
  .MobileContainer {
    padding: 24px 20px 10px !important;
  }

  .SteerContainer {
    padding-top: 15px !important;
  }

  .VehicleInfoPanel {
    padding: 20px 20px;
  }
}
