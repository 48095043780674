@use "../../../styles/common.scss" as *;

.PhoneInputContiner {
  width: 100%;
  height: 45px;
  border: 1px solid $theme-border-color-5;
  background-color: $theme-background-color-13;

  @include hover-supported {
    border: 1px solid $theme-border-color-9;
    box-shadow: 0 0 0 1px $theme-shadow-color-0;

    .PhoneInputButton {
      border-right: 1px solid $theme-border-color-9;
      box-shadow: 0 0 0 1px $theme-shadow-color-0;
    }
  }

  &:focus {
    border: 1px solid $theme-border-color-9;
    box-shadow: 0 0 0 1px $theme-shadow-color-0;
  }

  &--isError {
    border-color: $theme-border-color-13;
    box-shadow: 0 0 0 1px $theme-shadow-color-1;
  }

  &.isDisabled {
    opacity: 0.6;
    background-color: $theme-background-color-13;
    border-color: transparent;
    box-shadow: none;
  }
}

.PhoneInputButton {
  width: max-content;
  border-right: 1px solid $theme-border-color-5;
  border-radius: 5px 0 0 5px;
  background-color: transparent;
  cursor: pointer;

  &__flag {
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  @include hover-supported {
    .PhoneInputButton__arrow {
      & > svg {
        fill: $theme-fill-color-3;
      }
    }
  }

  &__arrow {
    min-width: 20px;
    width: 20px;
    height: 20px;

    & > svg {
      transition: all 0.3s;
    }

    &.isArrowRotate > svg {
      fill: $theme-fill-color-3;
      transform: rotate(-180deg);
    }
  }
}

.PhoneInput {
  width: 100%;
  height: 100%;
  border-radius: 0 5px 5px 0;
  background-color: transparent;
  border: none;
  outline: none;

  &::placeholder {
    color: $theme-text-color-4;
    font-weight: 500;
  }
}
