@use "../../../../styles/common.scss" as *;

.SpinnerWrapper {
  min-height: 200px;
}

.Alerts {
  &__NoData {
    display: flex;
    flex-direction: column;
    margin: 0 auto 50px;
    color: $theme-text-color-4;
    text-align: center;

    .icon {
      margin: 35px auto 20px;
      height: 160px;
      width: 160px;
    }
  }
}

.AlertItem {
  border: 1px solid $theme-border-color-4;
  border-radius: 10px;
  padding: 24px;

  &__Wrapper {
    margin-bottom: 20px;
  }

  &__TitleWrapper {
    padding-right: 50px;
  }

  .HeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__Title {
    font: normal normal bold 16px/20px Lato;
    letter-spacing: 0px;
    color: #191919;
    margin-bottom: 0;
  }

  &__Description {
    margin-top: 4px;
    font: normal normal normal 13px/20px Lato;
    letter-spacing: 0px;
    color: $theme-text-color-2;
  }

  .NotificationElement {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }

  .VerticalSeparator {
    background-color: $theme-fill-color-7;
    width: 1px;
    height: 26px;
    margin: 0 30px;
  }

  .RedIconColor {
    path {
      fill: $theme-fill-color-4;
    }
  }
  .BlueIconColor {
    path {
      fill: $theme-fill-color-12;
    }
  }
  .GreenIconColor {
    path {
      fill: $theme-fill-color-5;
    }
  }

  .DefaultIconColor {
    path {
      fill: $theme-fill-color-11;
    }
  }

  .DeleteIcon {
    &:hover {
      path {
        fill: $theme-fill-color-9;
      }
      cursor: pointer;
    }
  }
}

.ManageAlertsButton {
  width: 157px;
  height: 40px;
  padding-left: 26px;
  margin-left: auto;

  border-radius: 5px;
  border: none;

  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: $theme-text-color-19;

  background-image: url(../../../../assets/icons/manage-alerts.svg);
  background-color: $theme-background-color-19;
  background-position: 20px;
  background-repeat: no-repeat;
  &::before {
    content: "Manage Alerts";
  }

  &:hover {
    background-color: $theme-background-color-18;
  }
  &:focus {
    outline: none;
  }
  &:active {
    background-color: $theme-background-color-17;
    outline: none;
  }
}

.Activity {
  &__Wrapper {
    margin-top: 40px;

    .Title {
      display: flex;
      gap: 14px;
      align-items: center;
      font-weight: 700;
      line-height: 24px;

      span {
        margin-top: 5px;
      }
    }
  }

  &__Delimiter {
    width: calc(100% - 156px);
    height: 1px;
    background-color: $theme-border-color-4;
    margin: 14px 0 14px auto;
  }

  &__NoData {
    display: flex;
    flex-direction: column;
    margin: 0 auto 20px;
    padding-right: 15px;
    color: $theme-text-color-4;
    text-align: center;

    .icon {
      margin: 20px auto;
    }
  }

  &__BottomPadding {
    height: 30px;
  }

  &__ScrollWrapper {
    overflow: auto;
    max-height: 268px;
    margin-top: 40px;
  }

  &__Date {
    margin-top: 5px;
    min-width: 72px;
  }

  &__Icon {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 6px #3f4b8021;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    position: relative;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;

    &::after {
      content: "";
      height: 30px;
      width: 2px;
      background: #ebeff4;
      position: absolute;
      bottom: -30px;
      z-index: 10;
    }
  }

  &__Range,
  &__Value {
    font: normal normal normal 14px/13px Lato;
  }

  &__Range {
    color: $theme-text-color-2;
  }

  &__Day,
  &__Time {
    font: normal normal normal 12px/18px Lato;
    text-align: right;
  }

  &__Day {
    color: $theme-text-color-1;
  }

  &__Time {
    color: $theme-text-color-2;
  }

  &__TypeWrapper {
    width: 220px;
  }

  &__Type {
    font: normal normal bold 16px/20px Lato;
  }
  &__Range {
  }
  &__Value {
    color: #191919;
    display: flex;
    justify-content: flex-start;
  }

  &__NotificationIcon {
    margin-right: 48px;
  }

  &__Loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    color: $theme-text-color-2;
    margin-top: 20px;
  }
}

.Modal {
  &__Container {
    width: 516px;
    padding: 15px 0 30px;
    background-color: $theme-background-color-13;
    border-radius: 7px;
    box-shadow: 0px 7px 13px #073d7d07;

    &:first-child {
      padding: 15px 0 0px;
    }
  }

  &__ItemsContainer {
    height: calc(100vh - 532px);
    overflow: auto;
    padding: 0 13px 0 30px;
    margin-bottom: 1px;
  }

  &__ItemWrapper {
    width: 100%;
    border: 1px solid $theme-border-color-4;
    border-radius: 8px;
    padding: 17px 35px 0;
    margin-top: 8px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 30px;
    }
  }

  &__Item {
    justify-content: space-between;

    &__Label {
      text-align: left;
      font: normal normal bold 16px/34px Lato;
      letter-spacing: 0;
      color: $theme-text-color-0;
      margin: -5px 0;
    }
  }

  &__Label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__CounterLabel {
    margin: 5px 30px 15px;
    text-align: left;
    font: normal normal bold 18px/34px Lato;
    letter-spacing: 0;
    color: $theme-text-color-0;
    margin-left: 30px;

    span {
      text-align: left;
      font: normal normal normal 18px/13px Lato;
      letter-spacing: 0px;
      color: $theme-text-color-4;
    }
  }

  &__ToggleButton {
    margin: 5px 30px 15px;
    font: normal normal 400 14px/16px Lato;
    color: $theme-text-color-2;
    letter-spacing: 0px;
    cursor: pointer;

    &:hover {
      color: $theme-text-color-19;
    }
  }

  &__AlertFrequency {
    margin-top: 20px;
  }

  &__AlertFrequencyGroup {
    padding-left: 30px;
    margin-bottom: 0px;
  }

  &__AlertFrequencyLabel {
    font: normal normal 600 14px/8px Lato;
    color: $theme-text-color-2;
    padding-bottom: 12px;
  }

  &__AlertsFooter {
    border-top: none !important;
    padding: 35px 0 20px !important;
    position: sticky;
    bottom: 0;
    background: transparent linear-gradient(180deg, #f6f7f900 0%, #f6f7f9 50%);
    pointer-events: none;
    z-index: 2;
  }
}

.AlertsSearch {
  width: calc(100% - 60px) !important;
  margin: 0 auto 32px;
}

@media screen and (max-width: 1400px) {
  .Activity {
    &__NotificationIcon {
      margin-left: 20px;
      margin-right: 12px;
      &:last-child {
        margin-left: 12px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .Activity {
    &__ScrollWrapper {
      width: calc(100% + 20px);
    }
  }
  .Modal {
    &__AlertsFooter {
      background: linear-gradient(
        180deg,
        rgba(248, 249, 252, 0) 0%,
        #f8f9fc 59.5%,
        #f8f9fc 93.48%
      );
    }
  }
}

.rangeInput {
  margin-top: 24px;

  &:not(:last-child) {
    padding-bottom: 24px;
    border-bottom: 1px solid #ebeff4;
  }
}

@media screen and (max-width: 767px) {
  .ManageAlertsButton {
    width: 115px;

    &::before {
      content: "Manage";
    }
  }

  .AlertItem {
    padding: 0 0 24px;
    border: none;
    border-bottom: 1px solid $theme-border-color-4;
    border-radius: 0;

    .HeaderContainer {
      flex-wrap: wrap;
    }

    &__Wrapper {
      margin-bottom: 24px;
      &:nth-child(2) {
        margin-top: 24px;
      }
    }

    &__TitleWrapper {
      width: 100%;
      padding: 0;
      margin-bottom: 15px;
    }

    &__Description {
      @media (max-width: 485px) {
        max-width: 90%;
      }
    }

    &__ControlPanel {
      width: 100%;
      justify-content: flex-start;
      position: relative;

      > :last-child {
        min-width: 18px;
      }

      .DropdownWrapper {
        width: 20px;
        position: absolute;
        right: 0px;
        top: -39px;

        &.DropdownWrapperMobile {
          top: -63px;

          @media (max-width: 485px) {
            top: -83px;
          }
        }
      }
    }

    .NotificationElement {
      flex-wrap: wrap;
      justify-content: center;
      max-width: fit-content;
      height: 100%;

      @media (max-width: 485px) {
        min-height: 60px;
        max-width: 42px;
        align-content: space-between;

        &:first-child {
          border-right: 1px solid #d4d6e5;
          margin-right: 40px;
          padding-right: 40px;
          max-width: 82px;
        }

        svg {
          margin-right: 0 !important;
        }

        button {
          padding-inline: 0px;
        }
      }
    }
  }

  .Activity {
    &__Wrapper {
      & > div:first-child {
        min-width: 70%;
      }
    }

    &__ScrollWrapper {
      margin-top: 35px;
      max-height: 300px;
      width: calc(100% + 15px);
    }

    &__Item {
      flex-direction: column;
      align-items: flex-start !important;

      &:nth-last-child(2) {
        .Activity__Title {
          .Activity__Icon:after {
            height: 118px;
          }
        }
      }
    }

    &__Title {
      flex-direction: column;
      align-items: flex-start !important;
      width: 100%;
      padding-left: 65px;
      position: relative;
    }

    &__Icon {
      position: absolute;
      left: -23px;
      &:after {
        height: 140px;
        top: 42px;
      }
    }

    &__Date {
      margin-top: 0;
      margin-bottom: 4px;
      display: flex;
    }

    &__Day {
      margin-right: 10px;
    }

    &__TypeWrapper {
      width: 100%;
      padding-right: 10px;
    }

    &__Range {
      margin-top: 4px !important;
    }

    &__Value {
      padding: 9px 0 12px 65px;
      width: 100%;
    }

    &__NotificationIcon {
      margin-left: 65px;
    }

    &__Delimiter {
      width: calc(100% - 80px);
      margin: 15px 20px 15px 65px;
    }
  }

  .Modal {
    &__Container {
      width: 100%;
      height: 100%;
      padding: 5px 0;
      background-color: #fff;
      box-shadow: none;

      &:first-child {
        padding: 5px 0 0px;
      }
    }

    &__CounterLabel,
    &__ToggleButton {
      margin: 0px 0 20px;
    }

    &__CounterLabel {
      line-height: 24px;
    }

    &__ItemsContainer {
      height: auto;
      padding: 0;
    }

    &__ItemWrapper {
      padding: 0px 24px 0 31px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    &__Item {
      align-items: center;
      margin-bottom: 1rem;
      margin-top: 1rem;

      &__Label {
        font: normal normal bold 14px / 16px Lato;
        max-width: 65%;
      }
    }

    &__AlertFrequency {
      border-top: 1px solid $theme-border-color-4;
      border-radius: 0;
      margin-top: 24px;
      padding-top: 24px;
    }

    &__AlertFrequencyGroup {
      padding-left: 0px;
      margin-bottom: 35px;
    }

    &__AlertFrequencyLabel {
      font: normal normal 600 14px / 16px Lato;
      padding-bottom: 8px;
    }

    &__AlertsFooter {
      flex-wrap: nowrap;
      justify-content: center;
      padding: 0px 16px !important;
      height: 76px;
      clip-path: inset(-30px 0px 0px 0px);
      background: linear-gradient(
        180deg,
        rgba(246, 247, 249, 0) 0%,
        rgba(246, 247, 249, 0.7) 17.39%,
        #f6f7f9 100%
      );
    }
  }

  .AlertsSearch {
    margin: 0 0 24px 0 !important;
    width: 100% !important;
  }

  .rangeInput {
    margin-top: 20px;
  }
}

.Tooltip {
  background: #222840 !important;
  &::after {
    border-top-color: #222840 !important;
  }
}
