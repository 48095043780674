@use "../../../../../styles/common.scss" as *;

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}

.avatarImg {
  border-radius: 50%;
  object-fit: contain;
}

.profileIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid $theme-border-color-2;
  background-color: #ffffff;
}

.hasBorder {
  border: 1px solid $theme-border-color-2;
}
