@use "../../styles/common.scss" as *;

.wrapper {
  position: relative;
  flex-shrink: 0;
  user-select: none;

  .button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 16px;
    border-radius: 6px;
    min-height: 44px;
    background-color: $theme-background-color-7;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &:hover {
      background-color: $theme-background-color-4;
    }

    svg {
      transition: all 0.2s ease-out;

      path {
        fill: $theme-fill-color-11 !important;
      }
    }

    .title {
      color: $theme-text-color-1;
      text-align: center;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }
  }
}

.modal {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  width: 508px;
  max-width: none;

  .content {
    width: 100%;
    height: 100vh;
    border: none;
    border-radius: 0;
    background-color: $theme-background-color-10;
  }

  .summary {
    max-height: 40%;
    border-bottom: 1px solid $theme-border-color-2;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: $theme-background-color-13;

    .header {
      display: flex;
      align-items: center;
      gap: 8px;

      .title {
        margin-top: 2px;
        color: $theme-text-color-0;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
      }

      .closeButton {
        margin: 0 2px 0 auto;
        width: 14px;
        cursor: pointer;

        &:hover {
          path {
            fill: $theme-fill-color-2;
          }
        }

        path {
          fill: $theme-fill-color-11;
        }
      }
    }

    .activeFilters {
      position: relative;
      display: flex;
      gap: 16px;
      flex-wrap: nowrap;
      overflow: hidden;

      &.expanded {
        flex-wrap: wrap;
        overflow: auto;

        .filter {
          flex-wrap: wrap;
        }
      }

      .clearButton {
        display: flex;
        height: 32px;
        border: 1px solid $theme-border-color-4;
        border-radius: 6px;
        padding: 8px;
        cursor: pointer;

        &:hover {
          border-color: $theme-border-color-2;
        }

        &:active {
          border-color: $theme-border-color-17;
        }

        svg {
          width: 14px;
        }
      }

      .filter {
        max-width: 100%;
        display: flex;
        gap: 8px;
        align-items: center;
        flex-shrink: 0;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        user-select: none;

        .title {
          color: $theme-text-color-2;
          flex-shrink: 0;
        }

        .value {
          flex-shrink: 0;
          border-radius: 20px;
          border: 1px solid $theme-border-color-14;
          padding: 7px 8px;
          background: $theme-background-color-19;
          color: $theme-text-color-19;

          svg {
            margin-left: 8px;
            width: 12px;
            cursor: pointer;

            path {
              fill: $theme-fill-color-12;
            }

            &:hover {
              path {
                fill: $theme-fill-color-2;
              }
            }
            &:active {
              path {
                fill: $theme-fill-color-1;
              }
            }
          }
        }
      }

      .moreButton {
        position: absolute;
        right: 0;
        width: 100px;

        background: linear-gradient(90deg, transparent 0%, #fff 25%);

        &.hidden {
          display: none;
        }

        .clickArea {
          margin-left: auto;
          height: 32px;
          width: min-content;
          display: flex;
          gap: 8px;
          align-items: center;
          color: $theme-text-color-19;
          font-family: Lato;
          font-size: 14px;
          font-weight: 700;
          cursor: pointer;
          user-select: none;

          .icon {
            transform: rotate(180deg);

            path {
              fill: $theme-fill-color-12;
            }
          }

          &:hover {
            color: $theme-text-color-8;

            .icon path {
              fill: $theme-fill-color-2;
            }
          }

          &:active {
            color: $theme-text-color-7;

            .icon path {
              fill: $theme-fill-color-1;
            }
          }
        }
      }
    }
  }

  .container {
    padding: 24px 24px 0;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: auto;

    .card {
      box-shadow: 0px 7px 13px 0px #073d7d08;
      border-radius: 8px;
      border: 1px solid $theme-border-color-4;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      background-color: $theme-background-color-13;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        .title {
          color: $theme-text-color-0;
          font-family: Lato;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
        }

        .toggler {
          padding: 0;
        }
      }

      .selectorBoolean {
        overflow: hidden;
        width: fit-content;
        border: 1px solid transparent;
        border-radius: 8px;
        display: flex;
        gap: 1px;
        background-color: $theme-background-color-7;

        .item {
          padding: 16px;
          background-color: $theme-background-color-13;
          color: $theme-text-color-2;
          text-align: center;
          font-family: Lato;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          cursor: pointer;
          user-select: none;

          &:hover {
            background-color: $theme-background-color-44;
          }

          &.active {
            background-color: $theme-background-color-19;
            color: $theme-text-color-19;
            font-weight: 700;
          }
        }
      }

      .tagsInput {
        border: 1px solid $theme-border-color-4;
        border-radius: 8px;
        padding: 8px;
        display: flex;
        align-items: center;
        gap: 8px;

        &:hover,
        &:has(.input:focus) {
          border: 2px solid $theme-border-color-14;
          padding: 7px;
        }

        .tagsContainer {
          display: flex;
          flex-grow: 1;
          flex-wrap: wrap;
          gap: 8px;
          overflow: hidden;
          min-height: 28px;

          .tag {
            display: flex;
            flex-shrink: 0;
            border-radius: 20px;
            border: 1px solid $theme-border-color-14;
            padding: 5px 11px;
            background: $theme-background-color-19;
            color: $theme-text-color-19;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            user-select: none;

            svg {
              margin-left: 8px;
              width: 12px;
              height: 16px;
              cursor: pointer;

              path {
                fill: $theme-fill-color-12;
              }

              &:hover {
                path {
                  fill: $theme-fill-color-2;
                }
              }
              &:active {
                path {
                  fill: $theme-fill-color-1;
                }
              }
            }
          }

          .inputWrapper {
            flex-grow: 1;
            height: 28px;
            min-width: 50px;
            color: transparent;
            position: relative;

            .input {
              position: absolute;
              top: 0;
              left: 0;
              border: none;
              outline: none;
              padding: 0;
              width: 50px;
              min-width: 100%;
              height: 100%;
              color: $theme-text-color-1;
              font-weight: 700;
              font-size: 14px;
            }
          }
        }

        .clearButton {
          display: flex;
          cursor: pointer;
          margin-right: 8px;

          &:hover {
            border-color: $theme-border-color-2;
          }

          &:active {
            border-color: $theme-border-color-17;
          }

          &.hidden {
            display: none;
          }

          svg {
            width: 14px;
          }
        }
      }

      .tagsDropdown {
        .label {
          color: $theme-text-color-2;
          margin: 2px 0 10px;
          text-transform: capitalize;
          font-family: Lato;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
        }

        .tagsInput {
          .tagsContainer {
            max-height: 28px;

            &.expanded {
              max-height: unset;
            }
          }
          .dropdownButton {
            margin-right: 8px;
            cursor: pointer;

            svg {
              width: 10px;
              height: 28px;
            }

            &.active {
              path {
                fill: $theme-fill-color-12 !important;
              }

              svg {
                transform: rotate(180deg);
              }
            }
          }

          .expandButton {
            margin-right: 8px;
            cursor: pointer;
            user-select: none;
            color: $theme-text-color-19;
            font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .dropdown {
          margin-top: 8px;
          border-radius: 8px;
          border: 1px solid $theme-border-color-4;
          padding: 16px 16px 0;

          &.hidden {
            display: none;
          }

          .header {
            display: flex;
            flex-wrap: wrap;
            justify-content: initial;
            gap: 0;
            margin-bottom: 12px;

            .title {
              color: $theme-text-color-0;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 20px;
              text-transform: capitalize;
            }

            .counterDropdown {
              color: $theme-text-color-2;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              margin-left: 5px;
            }

            .button {
              margin-left: auto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              color: $theme-text-color-2;
              cursor: pointer;

              &:hover {
                color: $theme-text-color-19;
              }
            }

            .search {
              margin-top: 16px;
              width: 100%;
            }
          }

          .items {
            margin: 0 -16px;
            max-height: 260px;
            overflow: auto;

            .item {
              border-top: 1px solid $theme-border-color-4;

              &:first-child {
                border-top: none;
              }

              .checkbox {
                padding: 13px 16px;
              }
            }
          }
        }
      }
    }
  }

  .titleContainer {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .checkboxesCounter {
    color: $theme-text-color-4;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }

  .delimiter {
    width: 1px;
    height: 20px;
    background-color: #ebeff4;
  }

  .selectAllButton {
    background: none;
    border: none;
    color: $theme-text-color-2;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: $theme-text-color-1;
    }
  }

  .checkboxContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 164px;
    overflow-y: auto;
    padding-right: 5px;
  }

  .saveButton {
    margin: 24px;
    border-radius: 6px;
    padding: 14px 16px;
    background-color: $theme-background-color-28;
    color: $theme-text-color-6;
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.2s ease-out;

    &:hover {
      background-color: $theme-background-color-27;
    }

    &:active {
      background-color: $theme-background-color-26;
    }

    &.disabled {
      background-color: $theme-background-color-28;
      opacity: 0.5;
    }
  }
}

.counter {
  margin-right: 8px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background-color: $theme-background-color-31;
  color: $theme-text-color-13;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}

@media screen and (max-width: ($sm - 1px)) {
  .wrapper {
    .button {
      padding: 12px 11px 12px 13px;

      .title {
        display: none;
      }

      .counter {
        position: absolute;
        top: -6px;
        right: -6px;
        width: 20px;
        height: 20px;
        border: 2px solid $theme-border-color-7;
        font-size: 10px;
      }
    }
  }

  .modal {
    width: 100vw;

    .summary {
      padding: 16px;
      gap: 16px;
    }

    .container {
      padding: 16px 16px 20px;
      gap: 16px;
    }

    .saveButton {
      margin: auto 16px 20px;
    }
  }
}
