@use "./colors" as *;

.Input,
.TextArea {
  border: 1px solid $theme-border-color-4 !important;
  color: #49505a !important;
  font: normal normal 700 14px/20px Lato;
  height: 44px;
  &:focus,
  &:hover {
    border: 2px solid $theme-border-color-14 !important;
    box-shadow: none !important;
    padding: 5px 11px;
  }

  &::placeholder {
    font: normal normal normal 14px/25px Lato;
    color: $theme-text-color-4 !important;
  }

  &[readonly] {
    cursor: not-allowed;
    border: 1px solid $theme-border-color-4 !important;
    padding: 6px 12px;
  }

  &:disabled {
    background: #f8f9fc;
  }

  &.AlertTextArea {
    min-height: 110px;
    border-radius: 6px;
    padding: 16px;

    &:focus,
    &:hover {
      padding: 15px;
    }
  }

  &.AlertTextArea__NotEditable {
    border: 1px solid #ebeff4 !important;
    background-color: #f2f4f8 !important;
    resize: none;
    min-height: auto;
    height: auto;
    &:focus,
    &:hover {
      padding: 16px;
    }
  }
}

.InputLabel {
  text-align: left;
  font: normal normal 600 14px/36px Lato;
  letter-spacing: 0;
  color: #707d8f;
  margin: -3px 0;
}

.InputLabel__Optional {
  font: normal normal normal 13px/36px Lato;
  letter-spacing: 0;
  color: $theme-text-color-4;
}

.InputLabel__Optional__Order {
  font: normal normal 14px/36px Lato;
  letter-spacing: 0;
  color: $theme-text-color-4;
  margin-left: 4px;
}

.installDeviceInputWrapper {
  margin-top: 27px;
}

.deviceIdWrapper {
  margin-top: 27px;

  &.deviceIdEdited {
    margin-top: 6px;
  }
}

.FieldError {
  color: #d7282f;
  font: normal normal normal 13px/36px Lato;
}

.InputMasked {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  &:focus {
    outline: none;
  }
}

.InputHiddenPlaceholder {
  &::placeholder {
    color: $theme-text-color-1 !important;
  }

  &:focus {
    &::placeholder {
      color: #ffffff00 !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .installDeviceInputWrapper {
    margin-top: 24px;
  }
  .deviceIdWrapper {
    margin-top: 24px;

    &.deviceIdEdited {
      margin-top: 11px;
    }
  }
}
