@use "../../../../styles/common.scss" as *;

.arrow {
  margin-left: 4px;

  svg {
    path {
      fill: $theme-fill-color-11;
    }
  }
}
