@use "../../../styles/common.scss" as *;

.InputWrapper {
  position: relative;
  width: 100%;

  &__controls {
    display: flex;
    align-items: center;
    height: 22px;
    margin-bottom: 4px;

    &--disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  &__label {
    // .font(14px, 14px, 600);
    color: $theme-text-color-2;
  }

  &__additionalLabel {
    // .font(14px, 14px, 400);
    color: $theme-text-color-4;
    margin-left: 5px;
  }

  &__button {
    // .font(14px, 14px, 400);
    color: $theme-text-color-6;
    display: flex;
    align-items: center;
    height: 16px;
    margin: 0 5px;
    padding: 0 5px;
    cursor: pointer;

    &--add {
      color: $theme-text-color-19;

      // .hover-supported({
      //   color: $theme-text-color-8;

      //   & > svg {
      //     fill: $theme-fill-color-2;
      //   }
      // });

      &:active {
        color: $theme-text-color-7;

        & > svg {
          fill: $theme-fill-color-1;
        }
      }
    }

    &--remove {
      // .hover-supported({
      //   & > svg {
      //     fill: $theme-fill-color-4;
      //   }
      // });

      &:active {
        & > svg {
          fill: $theme-fill-color-4;
        }
      }
    }
  }

  &__icon {
    margin-right: 6px;
  }

  &__information {
    margin: 0 10px;
    cursor: pointer;
  }

  &__charactersCounter {
    // .font(13px, 13px, 700);
    color: $theme-text-color-2;
    margin-left: auto;
  }

  &__error {
    // .font(13px, 15px, 600);
    position: absolute;
    bottom: -20px;
    left: 0;
    color: $theme-text-color-14;
  }
}
