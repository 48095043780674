@use "../../../styles/common.scss" as *;

.wrapper {
  border-top: 1px solid $theme-border-color-0;

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 24px 16px;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: $theme-background-color-43;
    }

    &.active {
      background-color: $theme-background-color-43;
      .arrow {
        transform: rotate(-180deg);
      }
    }

    &.detailed {
      justify-content: start;
      .name {
        display: -webkit-box;
      }
      .arrow {
        display: block;
      }
    }

    .avatar {
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      background: url("../../../assets/icons/user-avatar.svg");
    }

    .name {
      display: none;
      margin: 0 12px;
      min-width: 110px;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      color: $theme-text-color-5;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .arrow {
      display: none;
      flex-shrink: 0;
      margin-left: auto;
      transition: transform 0.2s;
      path {
        fill: $theme-fill-color-11;
      }
    }
  }

  .menu {
    border: none !important;
    box-shadow: 2px 10px 50px #3f4b802e;
    border-radius: 8px !important;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
    transform: translate3d(0, 0px, 0px) !important;
    left: initial !important;
    right: -204px;

    .item {
      display: flex !important;
      align-items: center;
      width: 200px;
      height: 48px;
      padding: 16px;

      &:focus {
        outline: none;
      }

      &:hover,
      &:active {
        background-color: $theme-background-color-20 !important;
      }

      .icon {
        margin-right: 8px;
        width: 16px;
        height: 16px;
        path {
          fill: $theme-fill-color-11;
        }
      }

      .title {
        margin-top: 1px;
        font: normal normal 700 14px/16px Lato;
        letter-spacing: 0;
        color: $theme-text-color-1 !important;
      }
    }
  }
}

@media screen and (max-width: ($sm - 1px)) {
  .wrapper {
    .button {
      background-color: transparent !important;
    }

    .menu {
      position: static !important;
      width: 100%;
      border-radius: 0px !important;
      background-color: transparent !important;

      .item {
        width: 100%;
        height: 44px;
        border-top: 1px solid $theme-border-color-0;

        &:hover,
        &:active {
          background-color: transparent !important;
        }

        .icon {
          margin-right: 12px;
        }

        .title {
          color: $theme-text-color-5 !important;
        }
      }
    }
  }
}
