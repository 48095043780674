@use "../../../styles/common.scss" as *;

.RangeInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  @include screen-sm {
    flex-direction: row;
    align-items: center;
  }
}

.Label {
  line-height: 16px;
  display: flex;

  &.withInputs {
    margin-bottom: 8px;
  }

  .rangeBtn {
    font: normal normal bold 12px/16px Lato;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: 8px;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
    span {
      vertical-align: middle;
    }
  }

  .addRange {
    color: $theme-text-color-19;

    &:hover {
      color: $theme-text-color-8;
      svg {
        path {
          fill: $theme-fill-color-2;
        }
      }
    }
  }

  .deleteRange {
    color: $theme-text-color-14;

    &:hover {
      color: $theme-text-color-20;
      svg {
        path {
          fill: $theme-fill-color-13;
        }
      }
    }
  }
}

.RangeTextInput {
  max-width: 334px;
  width: 100%;

  @include screen-sm {
    max-width: 140px;
  }
}

.Separator {
  margin: 0 13px;
  &:after {
    content: "-";
  }
}

.Range {
  position: relative;
  width: 100%;
  height: 45px;
  color: $theme-text-color-4;

  &.withInputs {
    margin-left: 30px;
  }
}

.Slider {
  top: 50%;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  border: 3px solid $theme-border-color-8;
  transition: all 0.3s;
  cursor: pointer;
}

.Track {
  top: 50%;
  right: 0;
  left: 0;
  width: calc(100% - 16px);
  height: 5px;
  background-color: $theme-background-color-3;
  cursor: pointer;
}

.FullTrack {
  width: 100%;
  background-color: $theme-background-color-37;
}

.ActiveTrack {
  height: 5px;
  transition: all 0.3s;
}

.RangeMarks {
  width: calc(100% - 8px);
  user-select: none;
}

.RangeMark.isActive {
  color: $theme-text-color-0;
}

.filters {
  .rangeBtn {
    display: none;
  }

  .withInputs {
    font-family: Lato;
  }

  .RangeTextInput {
    max-width: 100px;
  }
}

@media screen and (max-width: 767px) {
  .RangeInput {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .RangeTextInput {
    width: 43% !important;
  }

  .Range {
    &.withInputs {
      margin: 18px 0 0;
    }
  }

  .filters {
    .RangeTextInput {
      max-width: initial;
    }
  }
}
