@use "../../../../styles/common.scss" as *;

.scrollContainer {
  display: block;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  &--isOverflowHidden {
    overflow: hidden;
  }

  &::-webkit-scrollbar {
    border-radius: 15px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: $theme-background-color-7;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $theme-background-color-39;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  @include screen-custom-max($md-1 - 1) {
    overflow: visible;
  }
}
