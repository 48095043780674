@use "./colors" as *;

.Dropdown__Toggle {
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 4px;
  margin-right: -15px;

  &:hover {
    .Toogler__Dot {
      background: #0879e1 0 0 no-repeat padding-box;
    }
  }

  &[aria-expanded="true"] {
    .Toogler__Dot {
      background: #0462b8 0 0 no-repeat padding-box;
    }
  }
}

.Dropdown__Menu {
  border: none !important;
  box-shadow: 2px 10px 50px #3f4b802e;
  border-radius: 8px !important;
  padding: 0px;
  margin: 0px 0px 0px 22px;
  transform: translate3d(-100%, 29px, 0px) !important;
}

.Dropdown__Item {
  display: flex !important;
  align-items: center;
  font: normal normal 600 14px/16px Lato;
  letter-spacing: 0;
  color: #49505a !important;
  height: 44px;
  padding: 14px 16px;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #f2f4f8 !important;
  }
  & > div {
    margin-top: 1px;
  }
}

.Dropdown__Icon {
  margin-right: 8px;
  width: 14px;
  height: 14px;
  path {
    fill: $theme-fill-color-11;
  }
}

.Toogler__Dot {
  height: 5px;
  width: 5px;
  background: $theme-background-color-39 0 0 no-repeat padding-box;
  border-radius: 50%;
  margin-bottom: 2px;

  &:last-child {
    margin-bottom: 0;
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: #ebeff4;
}
