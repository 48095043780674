@use "../../styles/common.scss" as *;

.psi {
  .header {
    margin: 16px;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    line-height: 16px;

    .title {
      color: $theme-text-color-0;
      font-weight: 700;
    }
    .count {
      color: $theme-text-color-2;
      font-weight: 400;
    }
  }

  .pressure {
    display: flex;
    align-items: center;
    padding: 14px 16px;
    border-bottom: 1px solid $theme-border-color-4;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    line-height: 16px;

    &:last-child {
      border: none;
    }

    .icon {
      margin-bottom: 1px;
      width: 16px;
    }

    .title {
      margin-left: 8px;
      color: $theme-text-color-1;
      font-weight: 600;
    }

    .value {
      margin-left: 5px;
      color: $theme-text-color-0;
      font-weight: 700;
    }
  }
}
