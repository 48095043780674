@use "./colors" as *;

.DangerAlert {
  width: 100%;
  font: 600 13px/18px Lato;
  border-radius: 0px;
}

#WarningAlert {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  width: 100%;
  font: 600 13px/18px Lato;
  color: $theme-text-color-14;
  background-color: $theme-background-color-38;
  border: 1px solid $theme-border-color-13;
  border-radius: 0px;

  button {
    padding: 9px 15px !important;
    &:hover {
      color: inherit;
      opacity: 1;
    }
    &:focus {
      outline: none;
    }
  }

  svg {
    margin-right: 10px;
    path {
      fill: $theme-fill-color-8;
    }
  }
}

#ConsentAlert {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  width: 100%;
  font: 600 13px/19px Lato;
  color: $theme-text-color-21;
  background-color: $theme-background-color-41;
  border: 1px solid $theme-border-color-15;
  padding-right: 70px;
  border-radius: 6px;

  button {
    &:hover {
      color: inherit;
      opacity: 1;
    }
    &:focus {
      outline: none;
    }
  }

  svg {
    margin-right: 10px;
    path {
      fill: $theme-fill-color-14;
    }
  }
}

.button {
  color: $theme-text-color-19;
  margin-left: auto;
  padding-left: 10px;
  font: bold 14px/20px Lato;

  svg {
    path {
      fill: $theme-fill-color-12 !important;
    }
  }

  &:hover {
    color: $theme-text-color-8;
    cursor: pointer;
    svg {
      path {
        fill: $theme-fill-color-2 !important;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .button {
    flex-shrink: 0;
  }
}

@media screen and (max-width: 767px) {
  #ConsentAlert,
  #WarningAlert {
    padding-right: 50px;
  }

  #ConsentAlert {
    font: 600 14px/20px Lato;
    flex-wrap: wrap;
    border-radius: 6px;
    padding: 14px 50px 14px 16px;

    svg {
      margin-right: auto;
      min-width: 16px;
      min-height: 13px;
      height: 13px;
      width: 16px;
    }

    button {
      font-size: 2rem;
      text-shadow: none;
      padding: 0.25rem 0.75rem;
      font-weight: 400;
    }

    .text {
      flex-basis: calc(100% - 24px);
    }
  }

  .button {
    padding-left: 24px;
    margin-top: 8px;
    flex-basis: 100%;

    svg {
      display: none;
    }
  }
}
