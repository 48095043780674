@use "../../../styles/common.scss" as *;

.Switch {
  display: flex;
  align-items: center;
  width: max-content;
  background: transparent;
  cursor: pointer;
  border: none;
  outline: none;

  &:focus {
    outline: none;
  }

  &.Switch__handle--disabled {
    pointer-events: none;

    .Switch__handle {
      opacity: 0.5;
    }
  }

  .Switch__left__label {
    margin-right: 10px;
  }

  .Switch__right__label {
    margin-left: 10px;
  }

  .Switch__left__label,
  .Switch__right__label {
    @include font(14px, 14px, 400);
    color: $theme-text-color-2;
  }

  .Switch__handle {
    position: relative;
    background-color: $theme-background-color-39;
    border-radius: 34px;
    padding: 2px;
    width: 42px;
    height: 22px;
    transition: all 0.2s ease-in-out;

    .Switch__toggle {
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background-color: $theme-background-color-13;
      margin-left: 0;
      padding: 0;
      transition: all 0.2s ease-in-out;
      position: absolute;
      left: 1;
      top: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 2px;
        height: 10px;
        background-color: $theme-background-color-39;
        transition: all 0.2s ease-in-out;
      }

      &:before {
        transform: rotateZ(45deg);
      }

      &:after {
        transform: rotateZ(-45deg);
      }
    }

    &.Switch__handle--isChecked {
      background-color: $theme-background-color-24;

      .Switch__toggle {
        margin-left: 20px;
        background-color: $theme-background-color-13;
        transform: rotateZ(-90deg);

        &:after,
        &:before {
          background-color: $theme-background-color-24;
        }

        &:before {
          transform: translateX(-1px) translateY(-2px) rotateZ(45deg);
          height: 5px;
        }
        &:after {
          transform: translateY(2px) rotateZ(-45deg);
          height: 8px;
        }
      }

      .ToggleRed {
        &:after,
        &:before {
          background-color: $theme-fill-color-4;
        }
      }
      .ToggleBlue {
        &:after,
        &:before {
          background-color: $theme-fill-color-12;
        }
      }
      .ToggleGreen {
        &:after,
        &:before {
          background-color: $theme-fill-color-5;
        }
      }

      @include hover-supported {
        background-color: $theme-background-color-23;
      }
    }
    @include hover-supported {
      background: $theme-background-color-0;
    }
  }
}

@media only screen and (max-width: 767px) {
  .Switch {
    .Switch__left__label,
    .Switch__right__label {
      font-size: 13px;
    }
  }
}
