@use "../../styles/common.scss" as *;

.TimePicker {
  position: relative;
  max-width: 260px;
  width: 100%;
  margin: 0 20px;

  @include screen-sm {
    max-width: 306px;
  }

  &__label {
    @include font(14px, 36px, 600);
    position: absolute;
    top: -30px;
    left: 0;
    color: $theme-text-color-2;
  }

  &__separator {
    color: $theme-text-color-1;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.TimeToggle {
  display: flex;
  min-width: 80px;
  width: 80px;
  height: 40px;
  border: 1px solid $theme-border-color-4;
  border-radius: 5px;

  @include screen-sm {
    min-width: 100px;
    width: 100px;
  }

  &__button {
    @include font(14px, 38px, 700);
    color: $theme-text-color-4;
    flex-basis: 50%;
    text-align: center;
    user-select: none;
    cursor: pointer;

    &:first-child {
      border-right: 1px solid $theme-border-color-4;
    }

    &--isActive {
      background-color: $theme-background-color-19;
      color: $theme-text-color-19;
    }

    &--isDisabled {
      opacity: 0.7;
      pointer-events: none;
      cursor: default;
    }
  }
}
