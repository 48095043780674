@use "../../../../styles/common.scss" as *;

.TirePressure {
  margin-top: 30px;

  &__Label {
    font-size: 18px;
    color: #74748b;
  }

  &__LabelAccent--low {
    font-weight: 700;
    color: #d7282f;
  }

  &__LabelAccent--normal {
    font-weight: 700;
    color: #29c07b;
  }

  &__Description {
    font-size: 14px;
    line-height: 20px;
    color: $theme-text-color-2;
  }

  &__Visual {
    height: 210px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__VisualLabel {
    font-size: 16px;
    color: #191919;

    &--low {
      color: #d7282f;
    }
  }

  &__VisualValue {
    font-size: 30px;
    font-weight: 700;
  }

  :global {
    $tireoptions: "0000", "0001", "0010", "0011", "0100", "0101", "0110", "0111",
      "1000", "1001", "1010", "1011", "1100", "1101", "1110", "1111";

    @each $option in $tireoptions {
      .car-tire-pressure-#{$option} {
        background-image: url(../../../../assets/icons/tires/car-tires-#{$option}.png);
      }
    }

    .tire-pressure-value-fl {
      top: 18px;
      left: 0px;
    }
    .tire-pressure-value-fr {
      top: 18px;
      right: 0px;
    }
    .tire-pressure-value-rr {
      top: 130px;
      right: 0px;
    }
    .tire-pressure-value-rl {
      top: 130px;
      left: 0px;
    }
  }
  .carBodyType {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: auto;
  }
}

.VisualParamsBlocksList {
  width: 100%;
  margin-top: 50px;

  :global {
    .visual-param-block-red {
      background-color: #ffd7dc;
    }
    .visual-param-block-yellow {
      background-color: #fff3dc;
    }
    .visual-param-block-green {
      background-color: $theme-background-color-25;
    }
    .visual-param-block-blue {
      background-color: #dbeefe;
    }
  }
}

.VisualParamsBlock {
  height: 160px;
  width: calc(50% - 10px);
  max-width: 176px;
  margin-bottom: 20px;
  border-radius: 5px;

  &:nth-child(odd) {
    margin-right: 20px;
  }

  &__Icon {
  }

  :global {
    $red-accent: #ff5151;
    $yellow-accent: #f8b131;
    $green-accent: #29c07b;
    $blue-accent: #118af9;

    .visual-param-block-icon {
      &-red {
        path,
        rect {
          fill: $red-accent;
        }
      }

      &-green {
        path,
        rect {
          fill: $green-accent;
        }
      }

      &-yellow {
        path,
        rect {
          fill: $yellow-accent;
        }
      }

      &-blue {
        path,
        rect {
          fill: $blue-accent;
        }
      }
    }

    .visual-param-block-value-description {
      &-red {
        color: $red-accent;
      }

      &-green {
        color: $green-accent;
      }

      &-yellow {
        color: $yellow-accent;
      }

      &-blue {
        color: $blue-accent;
      }
    }
  }

  &__Label {
    color: $theme-text-color-2;
    font-size: 500;
  }

  &__Value {
    font-size: 24px;
    font-weight: 700;
    color: $theme-text-color-0;
  }

  &__ValueDescription {
    font-size: 11px;
    font-weight: 900;
    text-transform: uppercase;
  }
}

.LifeIndicator {
  margin-top: 20px;

  & ~ .LifeIndicator {
    margin-top: 30px;
  }

  &__Title {
    width: 40%;
    font-size: 16px;
    font-weight: 500;
    color: $theme-text-color-2;
  }

  &__Icon {
    width: 20%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    &.fuelFilter {
      background-image: url(../../../../assets/icons/fuel-filter-life.svg);
      height: 35px;
    }
    &.engineOil {
      background-image: url(../../../../assets/icons/engine-oil-life.svg);
      height: 30px;
    }
  }

  &__Value {
    width: 40%;
    font-size: 24px;
    font-weight: 700;
  }

  &__Line {
    height: 16px;
    background-color: #eceff1;
    border-radius: 15px;
  }

  &__ValueLine {
    height: 16px;
    background: linear-gradient(90deg, #d7282f 0%, #f8b131 50%, #29c07b 100%);
    border-radius: 15px;
  }

  &__LineTick {
    width: 10%;
    height: 6px;
    border-right: 1px solid $theme-border-color-4;
  }

  &__ChangeLabel {
    font-size: 13px;
    color: #d7282f;
  }

  &__GoodLabel {
    font-size: 13px;
    color: #29c07b;
  }
}

.FuelUsageMetrics {
  margin-top: 35px;

  &__Title {
    font-weight: 700;
    color: #191919;
    font-size: 18px;
  }

  &__LineLabel {
    font-size: 14px;
    font-weight: 500;
    color: $theme-text-color-2;
  }

  &__LineValue {
    color: #191919;
    font-size: 18px;
    font-weight: 700;
  }

  &__LineValueDimension {
    font-size: 16px;
    font-weight: 400;
  }
}

@media screen and (max-width: 1200px) {
  .TirePressure {
    &__Visual {
      height: 170px;
    }
    :global {
      .tire-pressure-value-fl,
      .tire-pressure-value-fr {
        top: 8px;
      }

      .tire-pressure-value-rl,
      .tire-pressure-value-rr {
        top: 98px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .VisualParamsBlocksList {
    flex-wrap: nowrap !important;
    gap: 20px;
  }

  .VisualParamsBlock {
    max-width: 50%;

    &:nth-child(odd) {
      margin-right: 0;
    }
  }

  .TirePressure {
    &__Visual {
      margin: auto;
      width: 100%;
      max-width: 325px;
      height: 190px;
      background-size: contain;
    }
    :global {
      .tire-pressure-value-fl,
      .tire-pressure-value-fr {
        top: calc(25% - 35px);
      }

      .tire-pressure-value-rl,
      .tire-pressure-value-rr {
        top: calc(72% - 22px);
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .TirePressure {
    &__Visual {
      margin-top: 24px !important;
      height: 210px;
      max-width: 350px;
      background-size: auto;
    }
  }

  .VisualParamsBlocksList {
    margin-top: 40px;
    flex-wrap: wrap !important;
    gap: 0;
  }

  .VisualParamsBlock {
    width: 100%;
    max-width: none;

    &:nth-child(odd) {
      margin-right: 0;
    }
  }

  .LifeIndicator {
    padding-bottom: 20px;
    &__Title {
      width: 42%;
    }
    &__Icon {
      width: 16%;
    }
    &__Value {
      width: 42%;
    }
  }

  .FuelUsageMetrics {
    margin-top: 20px;
  }
}
