@use "./variables" as *;
@use "./colors" as *;

/* mixin for font */
@mixin font($size, $line-height, $weight) {
  font-size: $size;
  line-height: $line-height;
  font-weight: $weight;
}

/* mixin to truncate one and multi line text */
@mixin truncate-text($line-count: 1, $line-height: 0) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line-count;
  -webkit-box-orient: vertical;

  // & when ($line-height > 0) {
  //   max-height: unit($line-count * $line-height, px);
  // }
}

/* mixin enable hover only for device that allow */
@mixin hover-supported {
  @media not all and (pointer: coarse) {
    &:hover {
      @content;
    }
  }
}

/* allow to apply style for touch device */
@mixin is-touch-device() {
  @media (any-hover: none) {
    @content;
  }
}

/* mixin for element centering  */
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* mixin for flex */
@mixin flex($justify: null, $align: null, $direction: null, $wrap: null) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
  flex-wrap: $wrap;
}

/* mixin for position absolute and fixed */
@mixin position(
  $position,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin screen-xs() {
  @media only screen and (min-width: $xs) {
    @content;
  }
}

@mixin screen-sm() {
  @media only screen and (min-width: $sm) {
    @content;
  }
}

@mixin screen-md() {
  @media only screen and (min-width: $md) {
    @content;
  }
}

@mixin screen-lg() {
  @media only screen and (min-width: $lg) {
    @content;
  }
}

@mixin screen-custom($minWidth) {
  @media only screen and (min-width: $minWidth) {
    @content;
  }
}

@mixin screen-xs-tiny() {
  @media only screen and (min-width: $xs-tiny) {
    @content;
  }
}

@mixin screen-md-1() {
  @media only screen and (min-width: $md-1) {
    @content;
  }
}

@mixin screen-lg-1() {
  @media only screen and (min-width: $lg-1) {
    @content;
  }
}

@mixin screen-xl() {
  @media only screen and (min-width: $xl) {
    @content;
  }
}

@mixin screen-custom-min-and-max($minWidth, $maxWidth) {
  @media only screen and (min-width: $minWidth) and (max-width: $maxWidth) {
    @content;
  }
}

@mixin screen-custom-max($maxWidth) {
  @media only screen and (max-width: $maxWidth) {
    @content;
  }
}

@mixin screen-custom-maxHeight($maxHeight) {
  @media only screen and (max-height: $maxHeight) {
    @content;
  }
}

@mixin disabled-field() {
  pointer-events: none;
  background: $theme-background-color-10 0% 0% no-repeat padding-box;
  outline: none;
  color: $theme-text-color-4;
  -webkit-text-fill-color: $theme-text-color-4;
  opacity: unset;
}

@mixin disabled-label() {
  color: $theme-text-color-4;
}
