@use "../../../styles/common.scss" as *;
@use "./index.scss" as *;

// Light Button
.Button {
  @include button-general();
  @include button-size();

  &.Button__light {
    color: $theme-text-color-1;
    background: transparent;

    @include button-actions(
      $theme-background-color-7,
      $theme-background-color-4,
      $theme-text-color-1
    );
  }

  @include button-loading(
    $theme-border-color-0,
    fade($theme-border-color-0, 30%)
  );
}
