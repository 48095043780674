@use "../../../../styles/common.scss" as *;

:root {
  --alarm-height: 0px; /* default value */
}

.searchHider {
  justify-content: flex-end;
  margin-top: -54px;

  .CustomerVehicleSearch,
  .ArrowControls {
    display: none;
  }
}

.vehiclesListHider {
  display: none;
}

.CustomerVehiclesCount {
  margin-top: 12px;
  margin-bottom: 9px;
  font-weight: 700;
  color: #191919;
  font-size: 18px;
  line-height: 18px;

  &__number {
    font-weight: 400;
    color: $theme-text-color-4;
  }
}

.AddVehicle {
  &.AddVehicle__button {
    height: 44px;
    margin-left: 24px;
    padding-right: 0;
    padding-left: 22px;
    min-width: 130px;
    border-radius: 6px;
    border: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #d7282f;

    background-image: url(../../../../assets/icons/add-plus-red.svg);
    background-color: #ffd7dc;
    background-position: 16px;
    background-repeat: no-repeat;
    transition: background-color 0.3s ease-out;

    @media (max-width: 600px) {
      background-position: 10px;
    }

    &:hover {
      background-color: #f6cacf;
    }
    &:focus {
      outline: none;
    }
    &:active {
      background-color: #f1a3ad;
      outline: none;
    }
  }

  &.AddVehicle__buttonEmpty {
    margin-left: 0;
  }
}

.edgeShadows {
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 10;
    width: 20px;
  }

  &::before {
    left: -20px;
    background: linear-gradient(90deg, $theme-background-color-10, transparent);
  }

  &::after {
    right: -20px;
    background: linear-gradient(
      -90deg,
      $theme-background-color-10,
      transparent
    );
  }
}

.ScrollContainer {
  margin: 4px -20px -20px !important;
  padding: 20px;
  gap: 24px;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }

  &__tiles {
    width: calc(100% + 40px);
  }
}

.CustomerVehicleCard {
  padding: 0 !important;
  min-width: 333.5px;
  user-select: none;

  &__tiles {
    padding: 0;
    max-width: calc((100% - 72px) / 4);
  }

  &__wrapper {
    background-color: white;
    border-radius: 10px;
    min-height: 120px;
    min-width: 310px;
    cursor: pointer;
    border: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border 0.2s ease-out;
    box-shadow: 0px 7px 13px 0px rgba(7, 61, 125, 0.03);

    &:hover {
      border: 2px solid #118af9;
    }

    &--selected {
      background-color: #dbeefe;
      border: 2px solid #118af9;
    }
  }

  &__vehicleAvatar {
    position: relative;
    width: 80px;
    height: 80px;
    margin: auto 19px;
    background-position: center left 6px;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;
    border-radius: 38px;
    border: 1px solid #e9ebf2;
  }

  &__vehicleName {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #191919;
    margin-bottom: 2px;
    padding-right: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }

  &__vehicleParamName {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #707d8f;
    max-width: 130px;
  }

  &__vehicleParamValue {
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    color: #49505a;
  }
}

.EmptyVehicles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(50% + var(--alarm-height) / 2);
  left: 50%;
  transform: translate(-50%, 45%);

  .Title {
    font-size: 20px;
    font-weight: 700;
    color: $theme-text-color-4;
    margin-top: 24px;
    margin-bottom: 10px;
    line-height: 28px;
    white-space: nowrap;
  }

  .Message {
    white-space: pre-line;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: $theme-text-color-4;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 1599px) {
  .CustomerVehicleCard {
    &__tiles {
      max-width: calc((100% - 48px) / 3);
      flex-basis: 35%;
    }

    &__wrapper {
      &__tiles {
        min-width: calc((100% - 48px) / 3);
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .CustomerVehicleCard {
    &__tiles {
      max-width: calc((100% - 24px) / 2);
      flex-basis: 50%;
    }

    &__wrapper {
      &__tiles {
        min-width: calc((100% - 24px) / 2);
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .AddVehicle {
    &.AddVehicle__button {
      margin-left: 20px;
    }

    &.AddVehicle__buttonEmpty {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 767px) {
  .AddVehicle {
    &.AddVehicle__button {
      min-width: 45px !important;
      max-width: 45px;
      padding: 0;
      overflow: hidden;
      color: transparent;
      background-image: url(../../../../assets/icons/new-vehicle-mobile.svg);
      background-size: 22px;
      background-position: 13px;
    }

    &.AddVehicle__buttonEmpty {
      margin-left: 0;
      background-position: 16px;
      padding-left: 24px;
      color: #d7282f;
      background-size: unset;
      min-width: 130px !important;
      max-width: unset;
      background-image: url(../../../../assets/icons/add-plus-red.svg);
    }
  }

  .CustomerVehicleCard {
    max-width: 300px;
    min-width: 300px;

    &__tiles {
      max-width: 100%;
      flex-basis: 100%;
    }

    &__wrapper {
      max-width: 300px;
      min-width: 300px;

      &__tiles {
        min-width: 100%;
      }
    }
  }

  .CustomerVehicleCard__vehicleAvatar {
    width: 65px;
    height: 65px;
    margin: 20px 6% 20px 15px;
  }

  .CustomerVehicleCard__vehicleParamName {
    width: 38% !important;
    min-width: 94px;
  }

  .CustomerVehicleCard__vehicleParamValue {
    padding-left: 5px;
  }

  .EmptyVehicles {
    background-color: #f8f9fc;
    width: calc(100% + 10px);
    padding-bottom: 40px;

    svg {
      width: 160px;
      height: 160px;
    }
  }
}

.ArrowControls {
  display: flex;
  justify-content: space-between;
  width: 54px;
  height: 12px;
  margin-left: 24px;

  .ArrowButton {
    width: 17px;
    height: 12px;
    cursor: pointer;
    path {
      fill: $theme-fill-color-11;
    }
    &:hover {
      path {
        fill: $theme-fill-color-12;
      }
    }
    &:focus {
      outline: none;
    }
  }
  .RightArrow {
    transform: matrix(-1, 0, 0, -1, 0, 0);
    margin-left: 20px;
  }
}
