@use "../../styles/common.scss" as *;

.toggler {
  height: 64px;

  &.enableHover {
    @include hover-supported {
      background-color: $theme-background-color-10;
    }
  }
}

.content {
  overflow: auto;
}

.arrow {
  transition: all 0.3s;
  min-width: 24px;
  width: 24px;
}

.arrowRotated {
  transform: rotate(180deg);
}

.disabled {
  color: $theme-text-color-4;
}
