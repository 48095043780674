@use "../../styles/common.scss" as *;

.wrapper {
  position: relative;
  margin: 0 auto;
  max-width: 1390px;
  padding: 24px 40px;

  .title {
    color: $theme-text-color-0;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  .controls {
    margin-top: 24px;
    display: flex;
    gap: 24px;

    .search {
      flex-grow: 1;
    }
  }

  .panel {
    margin-top: 24px;
    box-shadow: 0px 7px 13px 0px #073d7d08;
    border-radius: 8px;
    background-color: $theme-background-color-13;

    &:has(.noData) {
      box-shadow: none;
      background-color: initial;
    }

    .table {
      position: relative;

      .spinner {
        min-height: 450px;
      }

      .header {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $theme-border-color-4;

        .cell {
          width: 25%;
          min-height: 64px;
          padding: 10px 22px;
          display: flex;
          align-items: center;
          color: $theme-text-color-2;
          font-family: Lato;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          user-select: none;
          transition: color 0.2s;

          &.sortable {
            cursor: pointer;

            &:hover {
              color: $theme-text-color-1;

              .icon {
                transform: opacity 0.2s;
                opacity: 1;

                path {
                  transition: fill 0.2s;
                  fill: $theme-fill-color-15;
                }
              }
            }
          }

          &.hidden {
            display: none;
          }

          &.vehicle {
            width: 80%;
            min-width: 240px;
            padding-right: 8px;
          }

          &.service {
            width: 40%;
            min-width: 105px;
          }

          &.shop {
            width: 60%;
          }

          &.check {
            min-width: 148px;
          }

          &.warnings {
            min-width: 83px;
          }

          &.psi {
            min-width: 96px;
          }

          &:has(.visible) {
            color: $theme-text-color-1;
          }

          .icon {
            margin-left: 7px;
            margin-bottom: 1px;
            min-width: 8px;
            opacity: 0.6;

            path {
              fill: $theme-fill-color-11;
            }

            &.visible {
              opacity: 1;

              path {
                fill: $theme-fill-color-15;
              }
            }

            &.asc {
              transform: rotate(180deg);
            }
          }

          .tooltip {
            padding: 16px !important;
            background-color: #222840e6;
            color: $theme-text-color-6;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }

      .row {
        border-bottom: 1px solid $theme-border-color-4;

        &:last-child {
          border-bottom: none;
        }

        .desktop {
          display: flex;
          align-items: center;

          .cell {
            width: 25%;
            min-height: 64px;
            padding: 10px 22px;
            display: flex;
            align-items: center;
            color: $theme-text-color-1;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            user-select: none;

            &.hidden {
              display: none;
            }

            &.vehicle {
              width: 80%;
              min-width: 240px;
              padding-right: 8px;
              cursor: pointer;

              &:hover {
                .name {
                  color: $theme-text-color-19;
                }
              }

              .icon {
                position: relative;
                margin-right: 16px;
                width: 44px;
                height: 44px;
                flex-shrink: 0;
                background-position: center left 3px;
                background-size: 180%;
                background-repeat: no-repeat;
                background-color: $theme-background-color-13;
                border-radius: 50%;
                border: 1px solid $theme-border-color-2;

                .check {
                  width: 32px;
                  height: 32px;
                  right: -10px;
                  top: -6px;
                }
              }

              .name {
                color: $theme-text-color-0;
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                transition: color 0.2s ease-out;
                overflow: hidden;
                text-wrap: nowrap;
                text-overflow: ellipsis;

                .customer {
                  display: block;
                  margin-top: 4px;
                  color: $theme-text-color-2;
                  font-family: Lato;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px;
                  overflow: hidden;
                  text-wrap: nowrap;
                  text-overflow: ellipsis;
                }
              }
            }

            &.service {
              width: 40%;
              min-width: 105px;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;

              .name {
                display: block;
                margin-top: 4px;
                color: $theme-text-color-2;
                font-family: Lato;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                width: 100%;
                overflow: hidden;
                text-wrap: nowrap;
                text-overflow: ellipsis;
              }
            }

            &.shop {
              width: 60%;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              overflow: hidden;
              font-family: Lato;
              font-style: normal;
              line-height: 16px;

              .name {
                width: 100%;
                color: $theme-text-color-0;
                font-size: 14px;
                font-weight: 700;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .address {
                width: 100%;
                margin-top: 4px;
                color: $theme-text-color-2;
                font-size: 12px;
                font-weight: 400;
                overflow: hidden;
                text-overflow: ellipsis;
                text-wrap: nowrap;
              }
            }

            &.check {
              min-width: 148px;
            }

            &.warnings {
              min-width: 83px;
            }

            &.psi {
              min-width: 96px;
            }

            .arrow,
            .cellMobile {
              display: none;
            }
          }

          .button {
            display: none;
          }
        }

        .mobile {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: ($lg-1 - 1px)) {
  .wrapper {
    padding: 24px;

    .panel {
      .table {
        .header {
          .cell {
            &.shop {
              width: 50%;
            }

            &.check {
              min-width: 124px;
            }

            &:not(.vehicle) {
              padding: 10px 8px;
            }
          }
        }

        .row {
          .desktop {
            .cell {
              &.shop {
                width: 50%;
              }

              &.check {
                min-width: 124px;
              }

              &:not(.vehicle) {
                padding: 10px 8px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: ($lg - 1px)) {
  .wrapper {
    .panel {
      .table {
        .header {
          &:has(.cell.collapsed) {
            padding-right: 90px;
          }

          .cell {
            &:not(.vehicle) {
              padding: 20px 16px;
            }

            &.vehicle {
              min-width: 35%;
            }

            &.collapsed {
              display: none;
            }
          }
        }

        .row {
          .desktop {
            position: relative;

            &:has(.cell.collapsed) {
              padding-right: 90px;
              .button {
                display: flex;
              }
            }

            .cell {
              &:not(.vehicle) {
                padding: 10px 16px;
              }

              &.vehicle {
                min-width: 35%;
              }

              &.collapsed {
                display: none;
              }
            }

            .button {
              position: absolute;
              right: 12px;
              align-items: center;
              gap: 8px;
              padding: 4px;
              cursor: pointer;
              user-select: none;

              &:hover {
                .text {
                  color: $theme-text-color-8;
                }

                .arrow {
                  background-image: url(../../assets/icons/arrow-blue-up.svg);
                  transform: rotate(180deg);
                }
              }

              .text {
                color: $theme-text-color-0;
                text-align: center;
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
              }

              .arrow {
                width: 9px;
                height: 6px;
                background-image: url(../../assets/icons/arrow-gray-down.svg);
                background-repeat: no-repeat;
                background-position: center;
              }
            }
          }

          &.expanded {
            border-radius: 8px;
            box-shadow: 2px 10px 50px 0px #3f4b8021;
            border-bottom: none;
            margin: 0 -8px;
            padding: 0 8px;
            background-color: $theme-background-color-13;

            .desktop {
              .button {
                .text {
                  color: $theme-text-color-8;
                }
                .arrow {
                  background-image: url(../../assets/icons/arrow-blue-up.svg);
                  transform: rotate(0deg);
                }
              }
            }

            .mobile {
              display: flex;
              padding: 0 8px;

              .cell {
                display: none;
                min-height: 64px;
                align-items: center;
                padding: 10px 24px;
                user-select: none;
                overflow: hidden;

                &.collapsed {
                  display: flex;
                }

                .parameter {
                  color: $theme-text-color-2;
                  font-family: Lato;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px;
                }

                .value {
                  display: flex;
                  align-items: center;
                  padding-left: 24px;
                  color: $theme-text-color-1;
                  font-family: Lato;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px;
                }

                .vehicle {
                  padding: 0 0 0 24px;
                  display: flex;
                  cursor: pointer;

                  &:hover {
                    .name {
                      color: $theme-text-color-19;
                    }
                  }

                  .icon {
                    position: relative;
                    margin-right: 16px;
                    width: 44px;
                    height: 44px;
                    flex-shrink: 0;
                    background-position: center left 3px;
                    background-size: 180%;
                    background-repeat: no-repeat;
                    background-color: $theme-background-color-13;
                    border-radius: 50%;
                    border: 1px solid $theme-border-color-2;

                    .check {
                      width: 32px;
                      height: 32px;
                      right: -10px;
                      top: -6px;
                    }
                  }

                  .name {
                    color: $theme-text-color-0;
                    font-family: Lato;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 16px;
                    transition: color 0.2s ease-out;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-wrap: nowrap;

                    .customer {
                      display: block;
                      margin-top: 4px;
                      color: $theme-text-color-2;
                      font-family: Lato;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 16px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-wrap: nowrap;
                    }
                  }
                }

                .service {
                  max-width: 170px;
                  padding-left: 24px;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  display: flex;
                  color: $theme-text-color-1;
                  font-family: Lato;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px;
                  user-select: none;

                  .name {
                    display: block;
                    margin-top: 4px;
                    color: $theme-text-color-2;
                    font-family: Lato;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-wrap: nowrap;
                  }
                }

                .shop {
                  margin-left: 24px;
                  font-family: Lato;
                  font-style: normal;
                  line-height: 16px;
                  max-width: calc(100% - 60px);

                  .name {
                    width: 100%;
                    color: $theme-text-color-1;
                    font-size: 14px;
                    font-weight: 400;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }

                  .address {
                    width: 100%;
                    margin-top: 4px;
                    color: $theme-text-color-2;
                    font-size: 12px;
                    font-weight: 400;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-wrap: nowrap;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: ($md-1 - 1px)) {
  .wrapper {
    padding: 24px 16px;

    .panel {
      .table {
        .header {
          .cell {
            &:not(.vehicle) {
              padding: 16px 12px;
            }
          }
        }

        .row {
          .desktop {
            .cell {
              &:not(.vehicle) {
                padding: 16px 12px;
              }
            }

            .button {
              .text {
                color: $theme-text-color-0 !important;
              }

              .arrow {
                background-image: url(../../assets/icons/arrow-gray-down.svg) !important;
                transform: rotate(0deg) !important;
              }
            }
          }

          &.expanded {
            .desktop {
              .button {
                .text {
                  color: $theme-text-color-8 !important;
                }
                .arrow {
                  background-image: url(../../assets/icons/arrow-blue-up.svg) !important;
                  transform: rotate(0deg) !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: ($sm - 1px)) {
  .wrapper {
    .controls {
      gap: 16px;
    }

    .panel {
      .table {
        .header {
          display: none;
        }

        .row {
          position: relative;

          &.expanded {
            border-radius: 8px;
            box-shadow: 2px 10px 50px 0px #3f4b8021;
            border-bottom: none;
            margin: 0;
            padding: 0;

            .mobile {
              flex-direction: column;

              .cell {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-basis: 100%;
                min-height: 56px;
                padding: 10px 24px;
                user-select: none;
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;

                &.vehicle,
                &.hidden {
                  display: none;
                }

                .service {
                  align-items: flex-end;
                  max-width: calc(100% - 146px);

                  .name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-wrap: nowrap;
                    width: 100%;
                  }
                }

                .shop {
                  max-width: calc(100% - 87px);
                  text-align: end;
                }

                .parameter {
                  color: $theme-text-color-2;
                  flex-shrink: 0;
                }

                .value {
                  display: flex;
                  align-content: center;
                  color: $theme-text-color-1;
                }
              }
            }
          }

          .desktop {
            padding-right: 68px !important;

            &:has(.cell:not(.vehicle, .hidden)) {
              .button {
                display: flex;
              }
            }

            .cell {
              display: none;

              &.vehicle {
                display: flex;
                order: -1 !important;
                width: initial;
                max-width: 100%;

                &:hover {
                  .name {
                    color: $theme-text-color-0;
                  }
                }
              }
            }

            .button {
              right: 0;
              padding: 30px 29px;

              .text {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
