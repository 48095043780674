.CountryFlag {
  min-width: 20px;
  height: 20px;
  background-size: 499px 200px;

  &__ad {background-position: 0 0}
  &__ae {background-position: -19.95px 0}
  &__af {background-position: -39.90px 0}
  &__ag {background-position: -59.85px 0}
  &__ai {background-position: -79.80px 0}
  &__al {background-position: -99.75px 0}
  &__am {background-position: -119.70px 0}
  &__ao {background-position: -139.65px 0}
  &__ar {background-position: -159.60px 0}
  &__as {background-position: -179.55px 0}
  &__at {background-position: -199.50px 0}
  &__au {background-position: -219.45px 0}
  &__aw {background-position: -239.40px 0}
  &__ax {background-position: -259.35px 0}
  &__az {background-position: -279.30px 0}
  &__ba {background-position: -299.25px 0}
  &__bb {background-position: -319.20px 0}
  &__bd {background-position: -339.15px 0}
  &__be {background-position: -359.10px 0}
  &__bf {background-position: -379.05px 0}
  &__bg {background-position: -399.00px 0}
  &__bh {background-position: -418.95px 0}
  &__bi {background-position: -438.90px 0}
  &__bj {background-position: -458.85px 0}
  &__bl {background-position: -478.80px 0}
  &__bm {background-position: 0 -20px}
  &__bn {background-position: -19.95px -20px}
  &__bo {background-position: -39.90px -20px}
  &__br {background-position: -59.85px -20px}
  &__bs {background-position: -79.80px -20px}
  &__bt {background-position: -99.75px -20px}
  &__bw {background-position: -119.70px -20px}
  &__by {background-position: -139.65px -20px}
  &__bz {background-position: -159.60px -20px}
  &__ca {background-position: -179.55px -20px}
  &__cc {background-position: -199.50px -20px}
  &__cd {background-position: -219.45px -20px}
  &__cf {background-position: -239.40px -20px}
  &__cg {background-position: -259.35px -20px}
  &__ch {background-position: -279.30px -20px}
  &__ci {background-position: -299.25px -20px}
  &__ck {background-position: -319.20px -20px}
  &__cl {background-position: -339.15px -20px}
  &__cm {background-position: -359.10px -20px}
  &__cn {background-position: -379.05px -20px}
  &__co {background-position: -399.00px -20px}
  &__cr {background-position: -418.95px -20px}
  &__cu {background-position: -438.90px -20px}
  &__cv {background-position: -458.85px -20px}
  &__cw {background-position: -478.80px -20px}
  &__cx {background-position: 0 -40px}
  &__cy {background-position: -19.95px -40px}
  &__cz {background-position: -39.90px -40px}
  &__de {background-position: -59.85px -40px}
  &__dj {background-position: -79.80px -40px}
  &__dk {background-position: -99.75px -40px}
  &__dm {background-position: -119.70px -40px}
  &__do {background-position: -139.65px -40px}
  &__dz {background-position: -159.60px -40px}
  &__ec {background-position: -179.55px -40px}
  &__ee {background-position: -199.50px -40px}
  &__eg {background-position: -219.45px -40px}
  &__eh {background-position: -239.40px -40px}
  &__er {background-position: -259.35px -40px}
  &__es {background-position: -279.30px -40px}
  &__et {background-position: -299.25px -40px}
  &__fi {background-position: -319.20px -40px}
  &__fj {background-position: -339.15px -40px}
  &__fk {background-position: -359.10px -40px}
  &__fm {background-position: -379.05px -40px}
  &__fo {background-position: -399.00px -40px}
  &__fr {background-position: -418.95px -40px}
  &__ga {background-position: -438.90px -40px}
  &__gb {background-position: -458.85px -40px}
  &__gd {background-position: -478.80px -40px}
  &__ge {background-position: 0 -60px}
  &__gf {background-position: -19.95px -60px}
  &__gg {background-position: -39.90px -60px}
  &__gh {background-position: -59.85px -60px}
  &__gi {background-position: -79.80px -60px}
  &__gl {background-position: -99.75px -60px}
  &__gm {background-position: -119.70px -60px}
  &__gn {background-position: -139.65px -60px}
  &__gp {background-position: -159.60px -60px}
  &__gq {background-position: -179.55px -60px}
  &__gr {background-position: -199.50px -60px}
  &__gt {background-position: -219.45px -60px}
  &__gu {background-position: -239.40px -60px}
  &__gw {background-position: -259.35px -60px}
  &__gy {background-position: -279.30px -60px}
  &__hk {background-position: -299.25px -60px}
  &__hn {background-position: -319.20px -60px}
  &__hr {background-position: -339.15px -60px}
  &__ht {background-position: -359.10px -60px}
  &__hu {background-position: -379.05px -60px}
  &__id {background-position: -399.00px -60px}
  &__ie {background-position: -418.95px -60px}
  &__il {background-position: -438.90px -60px}
  &__im {background-position: -458.85px -60px}
  &__in {background-position: -478.80px -60px}
  &__io {background-position: 0 -80px}
  &__iq {background-position: -19.95px -80px}
  &__ir {background-position: -39.90px -80px}
  &__is {background-position: -59.85px -80px}
  &__it {background-position: -79.80px -80px}
  &__je {background-position: -99.75px -80px}
  &__jm {background-position: -119.70px -80px}
  &__jo {background-position: -139.65px -80px}
  &__jp {background-position: -159.60px -80px}
  &__ke {background-position: -179.55px -80px}
  &__kg {background-position: -199.50px -80px}
  &__kh {background-position: -219.45px -80px}
  &__ki {background-position: -239.40px -80px}
  &__km {background-position: -259.35px -80px}
  &__kn {background-position: -279.30px -80px}
  &__kp {background-position: -299.25px -80px}
  &__kr {background-position: -319.20px -80px}
  &__kw {background-position: -339.15px -80px}
  &__ky {background-position: -359.10px -80px}
  &__kz {background-position: -379.05px -80px}
  &__la {background-position: -399.00px -80px}
  &__lb {background-position: -418.95px -80px}
  &__lc {background-position: -438.90px -80px}
  &__li {background-position: -458.85px -80px}
  &__lk {background-position: -478.80px -80px}
  &__lr {background-position: 0 -100px}
  &__ls {background-position: -19.95px -100px}
  &__lt {background-position: -39.90px -100px}
  &__lu {background-position: -59.85px -100px}
  &__lv {background-position: -79.80px -100px}
  &__ly {background-position: -99.75px -100px}
  &__ma {background-position: -119.70px -100px}
  &__mc {background-position: -139.65px -100px}
  &__md {background-position: -159.60px -100px}
  &__me {background-position: -179.55px -100px}
  &__mf {background-position: -199.50px -100px}
  &__mg {background-position: -219.45px -100px}
  &__mh {background-position: -239.40px -100px}
  &__mk {background-position: -259.35px -100px}
  &__ml {background-position: -279.30px -100px}
  &__mm {background-position: -299.25px -100px}
  &__mn {background-position: -319.20px -100px}
  &__mo {background-position: -339.15px -100px}
  &__mp {background-position: -359.10px -100px}
  &__mq {background-position: -379.05px -100px}
  &__mr {background-position: -399.00px -100px}
  &__ms {background-position: -418.95px -100px}
  &__mt {background-position: -438.90px -100px}
  &__mu {background-position: -458.85px -100px}
  &__mv {background-position: -478.80px -100px}
  &__mw {background-position: 0 -120px}
  &__mx {background-position: -19.95px -120px}
  &__my {background-position: -39.90px -120px}
  &__mz {background-position: -59.85px -120px}
  &__na {background-position: -79.80px -120px}
  &__nc {background-position: -99.75px -120px}
  &__ne {background-position: -119.70px -120px}
  &__nf {background-position: -139.65px -120px}
  &__ng {background-position: -159.60px -120px}
  &__ni {background-position: -179.55px -120px}
  &__nl {background-position: -199.50px -120px}
  &__no {background-position: -219.45px -120px}
  &__np {background-position: -239.40px -120px}
  &__nr {background-position: -259.35px -120px}
  &__nu {background-position: -279.30px -120px}
  &__nz {background-position: -299.25px -120px}
  &__om {background-position: -319.20px -120px}
  &__pa {background-position: -339.15px -120px}
  &__pe {background-position: -359.10px -120px}
  &__pf {background-position: -379.05px -120px}
  &__pg {background-position: -399.00px -120px}
  &__ph {background-position: -418.95px -120px}
  &__pk {background-position: -438.90px -120px}
  &__pl {background-position: -458.85px -120px}
  &__pm {background-position: -478.80px -120px}
  &__pr {background-position: 0 -140px}
  &__ps {background-position: -19.95px -140px}
  &__pt {background-position: -39.90px -140px}
  &__pw {background-position: -59.85px -140px}
  &__py {background-position: -79.80px -140px}
  &__qa {background-position: -99.75px -140px}
  &__re {background-position: -119.70px -140px}
  &__ro {background-position: -139.65px -140px}
  &__rs {background-position: -159.60px -140px}
  &__ru {background-position: -179.55px -140px}
  &__rw {background-position: -199.50px -140px}
  &__sa {background-position: -219.45px -140px}
  &__sb {background-position: -239.40px -140px}
  &__sc {background-position: -259.35px -140px}
  &__sd {background-position: -279.30px -140px}
  &__se {background-position: -299.25px -140px}
  &__sg {background-position: -319.20px -140px}
  &__sh {background-position: -339.15px -140px}
  &__si {background-position: -359.10px -140px}
  &__sj {background-position: -379.05px -140px}
  &__sk {background-position: -399.00px -140px}
  &__sl {background-position: -418.95px -140px}
  &__sm {background-position: -438.90px -140px}
  &__sn {background-position: -458.85px -140px}
  &__so {background-position: -478.80px -140px}
  &__sr {background-position: 0 -160px}
  &__ss {background-position: -19.95px -160px}
  &__st {background-position: -39.90px -160px}
  &__sv {background-position: -59.85px -160px}
  &__sy {background-position: -79.80px -160px}
  &__sz {background-position: -99.75px -160px}
  &__tc {background-position: -119.70px -160px}
  &__td {background-position: -139.65px -160px}
  &__tg {background-position: -159.60px -160px}
  &__th {background-position: -179.55px -160px}
  &__tj {background-position: -199.50px -160px}
  &__tk {background-position: -219.45px -160px}
  &__tl {background-position: -239.40px -160px}
  &__tm {background-position: -259.35px -160px}
  &__tn {background-position: -279.30px -160px}
  &__to {background-position: -299.25px -160px}
  &__tr {background-position: -319.20px -160px}
  &__tt {background-position: -339.15px -160px}
  &__tv {background-position: -359.10px -160px}
  &__tw {background-position: -379.05px -160px}
  &__tz {background-position: -399.00px -160px}
  &__ua {background-position: -418.95px -160px}
  &__ug {background-position: -438.90px -160px}
  &__us {background-position: -458.85px -160px}
  &__uy {background-position: -478.80px -160px}
  &__uz {background-position: 0 -180px}
  &__va {background-position: -19.95px -180px}
  &__vc {background-position: -39.90px -180px}
  &__ve {background-position: -59.85px -180px}
  &__vg {background-position: -79.80px -180px}
  &__vi {background-position: -99.75px -180px}
  &__vn {background-position: -119.70px -180px}
  &__vu {background-position: -139.65px -180px}
}
