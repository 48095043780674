@use "../../../../../styles/common.scss" as *;

.CountriesList {
  top: 55px;
  left: 0;
  background-color: $theme-background-color-13;
  z-index: $z-index-countries-list;

  &::-webkit-scrollbar {
    background-color: $theme-background-color-13;
    border-radius: 5px;
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: $theme-background-color-13;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $theme-background-color-2;
    border-radius: 15px;
    border: 5px solid $theme-border-color-8;
    height: 50px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &__divider {
    border-bottom: 1px solid $theme-border-color-5;
  }

  &__country {
    height: 45px;
    cursor: pointer;
    &:last-child {
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
    }
    &.isSelected {
      background-color: $theme-background-color-20;
    }

    @include hover-supported {
      background-color: $theme-background-color-20;
    }
  }

  &__countryName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
