@use "../../../../../../../styles/common.scss" as *;

.item {
  height: 60px;
  padding-left: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: background-color 0.3s ease-out;

  &.isSelected {
    background-color: #dbeefe;
  }

  &.isDisabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @include hover-supported {
    background-color: #f2f4f8;
  }
}

.shopName {
  @include truncate-text();
}

.shopAddress {
  @include truncate-text();
  margin-top: 5px;
}
