@use "../../styles/common.scss" as *;

.info {
  margin-left: 5px;

  &:hover {
    .icon {
      path,
      circle {
        fill: $theme-fill-color-2;
      }
    }
  }

  .icon {
    display: flex;
  }

  .tooltip {
    display: flex;
    padding: 0 !important;
    background-color: $theme-background-color-13;
    max-height: 355px;
    border-radius: 8px;
    box-shadow: 2px 10px 50px #3f4b8021;
  }
}
