@use "../styles/fonts.scss" as *;
@use "../styles/common.scss" as *;

::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

::-webkit-scrollbar-thumb {
  background-color: $theme-fill-color-11;
  border-radius: 10px;
  background-clip: content-box;
  border: 4px solid transparent;
}

.App {
  min-height: 100vh;
  background-color: $background;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  // line-height: 24px;

  // & > * {
  //   max-width: 1400px;
  // }

  .form-control {
    border: 2px solid $theme-border-color-4;
    color: $theme-text-color-1;
    padding-left: 50px;
    font-size: 14px;
    line-height: 36px;
    &:focus,
    &:hover {
      border: 2px solid #118af9;
      box-shadow: none;
    }
    &::placeholder {
      color: $theme-text-color-4;
    }
  }
}

.main-spinner {
  width: 100vw;
}
