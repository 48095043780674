@use "../../styles/common.scss" as *;

$body-c: #ffffff;
$square-c: #00c6ff;
$sq-hueUp: #d7282f;
$sq-hueDown: #dbeefe;

.SpinnerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  z-index: 1000;
  background: $body-c;

  .LoaderWrapper {
    box-sizing: border-box;
    width: 110px;
    height: 110px;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;

    .Square {
      width: 30px;
      height: 30px;
      background: $square-c;
      opacity: 0;
      animation: preloading-sq 3s ease infinite;
      filter: drop-shadow(8px 8px 7px darken($square-c, 10%));
    }

    .Square2 {
      animation-delay: 0.75s;
    }

    .Square3 {
      animation-delay: 2.25s;
    }

    .Square4 {
      animation-delay: 1.5s;
    }
  }

  .Text {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: $theme-text-color-1;
    margin-top: 20px;
  }
}

@keyframes preloading-sq {
  0% {
    opacity: 1;
    background: $sq-hueUp;
    filter: drop-shadow(8px 8px 7px $sq-hueUp);
  }
  25% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
    background: $sq-hueDown;
    filter: drop-shadow(8px 8px 7px $sq-hueDown);
  }
  100% {
    opacity: 0.3;
  }
}
