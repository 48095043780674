@use "../../styles/common.scss" as *;

.Day {
  @include font(13px, 13px, 600);
  position: relative;
  color: $theme-text-color-4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 35px;
  pointer-events: none;
  cursor: pointer;

  &:before {
    display: none;
    content: "";
    position: absolute;
    top: 0;
    left: 20px;
    width: 25px;
    height: 33px;
    background-color: $theme-background-color-19;
    z-index: 1;
  }

  &:after {
    display: none;
    content: "";
    position: absolute;
    top: 0;
    right: 20px;
    width: 25px;
    height: 33px;
    background-color: $theme-background-color-19;
    z-index: 1;
  }

  &--isCurrentMonth {
    color: $theme-text-color-1;
    pointer-events: auto;
  }

  & > span {
    position: relative;
    padding: 10px 0;
    text-align: center;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    border: 2px solid transparent;
    z-index: 12;
  }

  &--isToday {
    & > span {
      border-color: $theme-border-color-4;
    }
  }

  &--isSelected {
    & > span {
      border-color: $theme-border-color-14;
      background-color: $theme-background-color-19;
    }
  }

  &--isInRange {
    border-top: 1px solid $theme-border-color-8;
    border-bottom: 1px solid $theme-border-color-8;
    background-color: $theme-background-color-19;
  }

  &--isStartEdge {
    border-top: 1px solid $theme-border-color-8;
    border-bottom: 1px solid $theme-border-color-8;

    &:before {
      display: block;
    }

    &:after {
      display: none !important;
    }

    & > span {
      border-color: $theme-border-color-14;
      background-color: $theme-background-color-19;
    }
  }

  &--isEndEdge {
    border-top: 1px solid $theme-border-color-8;
    border-bottom: 1px solid $theme-border-color-8;

    &:after {
      display: block;
    }

    & > span {
      border-color: $theme-border-color-14;
      background-color: $theme-background-color-19;
    }
  }

  &.Day--isStartEdge.Day--isEndEdge {
    &:after,
    &:before {
      display: none;
    }
  }

  &:hover {
    & > span {
      border: 2px solid $theme-border-color-14;
      background-color: $theme-background-color-19;
    }
  }
}

@media screen and (max-width: 767px) {
  .Day {
    max-width: 14.285%;
  }
}
