@use "../../../../../../styles/common.scss" as *;

.listWrapper {
  width: 100%;
  max-width: 365px;
  padding-bottom: 0 !important;
}

.list {
  max-height: 270px;
  max-width: 348px;
  overflow-y: auto;
  margin: 0;
  margin-right: -20px;
}

.item {
  padding-right: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid $theme-border-color-4;
  cursor: pointer;
  overflow: hidden;

  @include hover-supported {
    .vehicleName {
      color: $theme-text-color-8;
    }
  }

  &.warningItem {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 20px;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 20px;
  }
}
