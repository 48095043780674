@use "../../styles/common.scss" as *;

.AddCustomer {
  &.AddCustomer__button {
    min-width: 160px;
    height: 45px;
    padding-left: 30px;
    margin-left: 24px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #d7282f;

    background-image: url(../../assets/icons/add-plus-red.svg);
    background-color: #ffd7dc;
    background-position: 20px;
    background-repeat: no-repeat;
    transition: background-color 0.3s ease-out;

    &:after {
      content: "Add Customer";
    }

    &:hover {
      background-color: #f6cacf;
    }

    &:focus {
      outline: none;
    }

    &:active {
      background-color: #f1a3ad;
      outline: none;
    }
  }

  &.AddCustomer__buttonEmpty {
    margin-left: 0;
  }
}

.CustomerList {
  padding-top: 30px;
}

.Container {
  padding: 24px 40px;
  margin: 0 auto;
  position: relative;
  max-width: 1390px;

  &.noIndents {
    padding: 0px;
  }
}

.HeaderContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;

  .Title {
    margin: 2px auto 0 0;
    color: #191919;
    font: normal 700 24px/32px Lato;
  }

  .CounterContainer {
    flex-shrink: 0;
  }
}

.EmptyCustomers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);

  .Title {
    font-size: 20px;
    font-weight: 700;
    color: $theme-text-color-4;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .Message {
    white-space: pre-line;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: $theme-text-color-4;
    margin-bottom: 40px;
  }
}

.divider {
  width: 1px;
  height: 24px;
  background: $theme-background-color-4;
  margin: 0 16px;
}

@media screen and (max-width: ($lg-1 - 1px)) {
  .Container {
    padding: 24px;
  }
}

@media screen and (max-width: 1023px) {
  .CustomerItemContainer {
    margin-bottom: 20px !important;
  }

  .Container {
    padding: 24px 16px;
  }

  .AddCustomer {
    &.AddCustomer__button {
      margin-left: 20px;
      min-width: 152px;

      &:active {
        opacity: 0.5;
      }
    }

    &.AddCustomer__buttonEmpty {
      margin-left: 0;
    }
  }

  .HeaderContainer {
    margin-bottom: 20px;
    flex-wrap: wrap;

    .Title {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .CustomerList {
    padding-top: 2px;
  }

  .CustomerItemContainer {
    margin-bottom: 12px !important;
  }

  .AddCustomer {
    &.AddCustomer__button {
      background-position: 11px;
      min-width: 45px;
      background-image: url(../../assets/icons/add-customer.svg);
      background-size: 23px;

      &:after {
        content: "";
      }
    }

    &.AddCustomer__buttonEmpty {
      background-image: url(../../assets/icons/add-plus-red.svg);
      background-position: 15px;
      background-size: unset;
    }
  }
}
