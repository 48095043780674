@use "../colors" as *;

/* Font weight */
.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

/* Font size */
.font-xs {
  font-size: 12px;
}

.font-sm {
  font-size: 13px;
}

.font-md {
  font-size: 14px;
}

.font-lg {
  font-size: 16px;
}

.font-xl {
  font-size: 18px;
}

.font-2xl {
  font-size: 20px;
}

.font-3xl {
  font-size: 24px;
}

.font-4xl {
  font-size: 30px;
}

.font-5xl {
  font-size: 36px;
}

/* Font color */
.text-color-primary {
  color: $theme-text-color-0;
}

.text-color-secondary {
  color: $theme-text-color-1;
}

.text-color-tertiary {
  color: $theme-text-color-2;
}

.text-color-quaternary {
  color: $theme-text-color-4;
}

.text-color-quinary {
  color: $theme-text-color-6;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
