@use "../../../styles/common.scss" as *;

.TextInput {
  // input-mixins > .input-general(0 20px);
  @include font(14px, 36px, 700);
  color: $theme-text-color-1;
  width: 100%;
  height: 45px;
  padding: 0 20px;
  border-radius: 5px;
  border: 1px solid $theme-border-color-5;

  &::placeholder {
    color: $theme-text-color-4;
    font-weight: 500;
  }

  @include hover-supported {
    border-color: $theme-border-color-14;
    box-shadow: 0 0 0 1px $theme-shadow-color-0;
  }

  &:focus {
    border-color: $theme-border-color-14;
    box-shadow: 0 0 0 1px $theme-shadow-color-0;
    outline: none;
  }

  &--isError {
    border-color: $theme-border-color-13 !important;
    box-shadow: 0 0 0 1px $theme-shadow-color-1 !important;
  }

  &:disabled {
    opacity: 0.6;
    background-color: $theme-background-color-13;
    border-color: transparent;
    box-shadow: none;
  }
}
