@use "../../styles/common.scss" as *;

.wrapper {
  max-width: 100%;
  border-radius: 7px;
}

.table {
  display: table;
  width: 100%;

  &--infiniteScrollDisplay {
    display: block;
  }

  .head {
    height: 64px;
    margin-bottom: 12px;
  }

  .bodyCell {
    &.isColumnSticky {
      background-color: inherit;
      z-index: 100;
    }
  }
}

.boxShadow {
  box-shadow: 0 7px 13px #073d7d07;
}

.rowExpanded {
  position: relative;
  background: $theme-background-color-13;
  z-index: 99;
  border-radius: 8px;
}

@media screen and (max-width: 767px) {
  .table {
    display: initial;
  }
}
