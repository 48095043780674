@use "../../../../styles/common.scss" as *;

.SpinnerWrapper {
  min-height: 567px;
}

.GeneralVehicleData {
  &__recordBox {
    min-height: 52px;
  }

  &__vehicleName {
    color: $theme-text-color-0;
    font-size: 24px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
  }

  &__vehicleStyle {
    font-size: 14px;
    color: $theme-text-color-2;
    line-height: 36px;
    font-weight: 400;
  }

  &__recordLabel {
    align-self: center;
    padding-right: 24px;
    font-size: 14px;
    color: $theme-text-color-2;
  }

  &__recordValue {
    align-self: center;
    font-weight: 700;
    color: #191919;
    font-size: 16px;
    max-width: 85%;
    &--deviceConnected,
    &--ignitionOn {
      color: #29c07b;
    }
    &--deviceDisconnected {
      color: #d7282f;
    }
    &--ignitionOff {
      color: $theme-text-color-2;
    }
    &--ignitionOffIcon {
      position: relative;
      top: 3px;
      right: 1px;
    }
    &--shop {
      width: 60%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      overflow: hidden;
      font-family: Lato;
      font-style: normal;
      line-height: 16px;

      .name {
        width: 100%;
        color: $theme-text-color-0;
        font-size: 14px;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .address {
        width: 100%;
        margin-top: 4px;
        color: $theme-text-color-2;
        font-size: 12px;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
      }
    }
  }
}

.OverviewDelimiter {
  height: 1px;
  background-color: #ebeff4;
  margin-top: 20px;
}

@media screen and (max-width: 1023px) {
  .GeneralVehicleData {
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .GeneralVehicleData {
    &__vehicleName {
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 0 !important;
    }

    &__vehicleStyle {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      margin-top: 12px;
      margin-bottom: 10px;
    }

    &__recordBox {
      flex-direction: column;
      padding-bottom: 15px;
      border-bottom: 1px solid #ebeff4;
    }

    &__recordLabel {
      align-self: flex-start;
    }

    &__recordValue {
      align-self: flex-start;
      text-align: left !important;
      margin-top: 10px;

      &--shop {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .OverviewDelimiter {
    display: none;
  }
}
