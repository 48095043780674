@use "../../../../styles/common.scss" as *;

.row {
  height: 64px;
  background-color: $theme-background-color-13;
  box-shadow: 0px 7px 13px #073d7d07;
  border-radius: 8px;

  @include screen-custom-max($sm - 1) {
    box-shadow: none;
    margin-bottom: 0px;
  }
}

.toggler {
  overflow: hidden;

  &.isSelected {
    background-color: $theme-background-color-19;
  }

  @include screen-custom(1200px) {
    padding-right: 0;
    padding-left: 0;
  }

  @include screen-custom-max($sm - 1) {
    box-shadow: 0px 7px 13px #073d7d07;
  }
}

.togglerExpanded {
  padding-left: 15px;
  border-radius: 7px 7px 0 0;
  box-shadow: none;
}

.arrowOpened {
  fill: $theme-fill-color-12;
}

.rowInactive {
  opacity: 0.3;
}

.isExpanded {
  height: auto !important;
}

.rowMargin {
  margin-bottom: 12px;

  @include screen-custom-max($sm - 1) {
    margin-bottom: 10px;
  }
}
