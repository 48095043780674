@use "../../../styles/common.scss" as *;
@use "./index.scss" as *;

// Primary Button
.Button {
  @include button-general();
  @include button-size();

  &.Button__primary {
    color: $theme-text-color-6;
    background-color: $theme-background-color-16;

    @include button-actions(
      $theme-background-color-15,
      $theme-background-color-14
    );
  }

  @include button-loading();
}
