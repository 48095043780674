@use "../../styles/common.scss" as *;

.wrapper {
  position: relative;
  margin: 0 auto;
  max-width: 1390px;
  padding: 24px 40px;

  .title {
    color: $theme-text-color-0;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  .tenantName {
    color: $theme-text-color-2;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .section {
    margin-top: 24px;
    box-shadow: 0px 7px 13px 0px #073d7d08;
    border-radius: 8px;
    background-color: $theme-background-color-13;

    .summary {
      display: flex;
      justify-content: space-between;
      gap: 32px;
      padding: 24px;

      .card {
        flex-grow: 1;
        display: flex;
        align-items: center;
        gap: 16px;

        .info {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .title {
            color: $theme-text-color-2;
            font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }

          .number {
            color: $theme-text-color-0;
            font-family: Lato;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
          }

          .link {
            margin-right: auto;
            color: $theme-text-color-19;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            cursor: pointer;
            user-select: none;

            .icon {
              margin: 0 0 2px 8px;
              transform: rotate(90deg);

              path {
                fill: $theme-fill-color-12;
              }
            }

            &:hover {
              color: $theme-text-color-8;

              .icon path {
                fill: $theme-fill-color-2;
              }
            }

            &:active {
              color: $theme-text-color-7;

              .icon path {
                fill: $theme-fill-color-1;
              }
            }
          }
        }
      }

      .divider {
        width: 1px;
        background-color: $theme-background-color-7;

        &.additional {
          display: none;
        }
      }
    }

    .vehicles {
      position: relative;

      .spinner {
        min-height: 450px;
      }

      .header {
        padding: 24px;
        display: flex;
        align-items: center;

        &:has(~ .noData) {
          .counter {
            display: none;
          }
        }

        .icon {
          margin-right: 16px;
        }

        .title {
          margin-right: 24px;

          .name {
            margin-bottom: 4px;
            color: $theme-text-color-0;
            font-family: Lato;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
          }

          .description {
            color: $theme-text-color-2;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }

        .counter {
          display: flex;
          align-items: center;
          margin-left: auto;
          padding: 12px 16px;
          border-radius: 24px;
          border: 1px solid $theme-border-color-4;
          background: $theme-background-color-10;

          .text {
            margin: 0 4px 0 8px;
            color: $theme-text-color-2;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }

          .count {
            color: $theme-text-color-0;
            font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
          }
        }
      }

      .table {
        &:has(.cell.shop) {
          overflow: auto;
        }

        .tableHeader {
          display: flex;
          align-items: center;
          border-top: 1px solid $theme-border-color-4;
          border-bottom: 1px solid $theme-border-color-4;

          &:has(.cell.shop) {
            min-width: 1060px;
          }

          .cell {
            width: 25%;
            min-height: 64px;
            padding: 10px 22px;
            display: flex;
            align-items: center;
            color: $theme-text-color-2;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            user-select: none;
            transition: color 0.2s;

            &:hover:not(.shop) {
              cursor: pointer;
              color: $theme-text-color-1;

              .icon {
                transform: opacity 0.2s;
                opacity: 1;

                path {
                  transition: fill 0.2s;
                  fill: $theme-fill-color-15;
                }
              }
            }

            &.vehicle {
              width: 400px;
              min-width: 24%;
              padding: 10px 24px;

              &:has(~ .shop) {
                display: none;
              }
            }

            &.customer {
              width: 300px;
              min-width: 18%;

              &:has(~ .shop) {
                display: none;
              }
            }

            &.vehicleCustomer {
              width: 400px;
              min-width: 24%;
              padding: 10px 24px !important;
              display: none;

              &:has(~ .shop) {
                display: flex;
              }
            }

            &.shop {
              min-width: 250px;
            }

            &.check {
              min-width: 124px;
            }

            &:has(.visible) {
              color: $theme-text-color-1;
            }

            .icon {
              margin-left: 7px;
              margin-bottom: 1px;
              min-width: 8px;
              opacity: 0.6;

              path {
                fill: $theme-fill-color-11;
              }

              &.visible {
                opacity: 1;

                path {
                  fill: $theme-fill-color-15;
                }
              }

              &.asc {
                transform: rotate(180deg);
              }
            }

            .tooltip {
              &_psi {
                padding: 16px !important;
                background-color: #222840e6;
                color: $theme-text-color-6;
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
              }
            }
          }
        }

        .row {
          display: flex;
          align-items: center;
          border-bottom: 1px solid $theme-border-color-4;

          &:has(.cell.shop) {
            min-width: 1060px;
          }

          &:nth-child(n + 7) {
            display: none;
          }

          .cell {
            width: 25%;
            min-height: 64px;
            padding: 10px 22px;
            display: flex;
            align-items: center;
            color: $theme-text-color-1;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            user-select: none;

            &.customer {
              width: 300px;
              min-width: 18%;

              &:has(~ .shop) {
                display: none;
              }
            }

            &.check {
              min-width: 124px;
            }

            &.vehicle {
              width: 400px;
              min-width: 24%;
              cursor: pointer;

              &:hover {
                .name {
                  color: $theme-text-color-19;
                }
              }

              .icon {
                position: relative;
                margin-right: 16px;
                width: 44px;
                height: 44px;
                flex-shrink: 0;
                background-position: center left 3px;
                background-size: 180%;
                background-repeat: no-repeat;
                background-color: $theme-background-color-13;
                border-radius: 50%;
                border: 1px solid $theme-border-color-2;

                .check {
                  width: 32px;
                  height: 32px;
                  right: -10px;
                  top: -6px;
                }
              }

              .name {
                color: $theme-text-color-0;
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                transition: color 0.2s ease-out;
              }

              .customer {
                display: none;
                overflow: hidden;
                text-wrap: nowrap;
                text-overflow: ellipsis;
              }

              &:has(~ .shop) {
                .name {
                  overflow: hidden;
                  text-wrap: nowrap;
                  text-overflow: ellipsis;
                }

                .customer {
                  display: block;
                  margin-top: 4px;
                  color: $theme-text-color-2;
                  font-family: Lato;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px;
                }
              }
            }

            &.shop {
              min-width: 250px;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              overflow: hidden;
              font-family: Lato;
              font-style: normal;
              line-height: 16px;

              .name {
                width: 100%;
                color: $theme-text-color-0;
                font-size: 14px;
                font-weight: 700;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .address {
                width: 100%;
                margin-top: 4px;
                color: $theme-text-color-2;
                font-size: 12px;
                font-weight: 400;
                overflow: hidden;
                text-overflow: ellipsis;
                text-wrap: nowrap;
              }
            }

            .arrow,
            .cellMobile {
              display: none;
            }
          }

          .arrow,
          .cellMobile {
            display: none;
          }
        }
      }
      .button {
        margin: auto;
        width: fit-content;
        padding: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $theme-text-color-19;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        cursor: pointer;
        user-select: none;

        .icon {
          margin-left: 8px;
          transform: rotate(90deg);

          path {
            fill: $theme-fill-color-12;
          }
        }

        &:hover {
          color: $theme-text-color-8;

          .icon path {
            fill: $theme-fill-color-2;
          }
        }

        &:active {
          color: $theme-text-color-7;

          .icon path {
            fill: $theme-fill-color-1;
          }
        }
      }

      .noData {
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
          margin: 40px auto 20px;
          height: 160px;
          width: 160px;
        }

        .text {
          margin-bottom: 64px;
          color: $theme-text-color-4;
          text-align: center;
          font-family: Lato;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }
      }
    }

    .services {
      position: relative;

      .spinner {
        min-height: 450px;
      }

      .header {
        padding: 24px;
        display: flex;
        align-items: center;

        .icon {
          margin-right: 16px;
        }

        .title {
          .name {
            margin-bottom: 4px;
            color: $theme-text-color-0;
            font-family: Lato;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
          }

          .description {
            color: $theme-text-color-2;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }

      .table {
        &:has(.cell.shop) {
          overflow: auto;
        }

        .row:nth-child(n + 7) {
          display: none;
        }

        .tableHeader {
          display: flex;
          align-items: center;
          border-top: 1px solid $theme-border-color-4;
          border-bottom: 1px solid $theme-border-color-4;

          &:has(.cell.shop) {
            min-width: 1000px;
          }

          .cell {
            width: 25%;
            min-height: 64px;
            padding: 10px 24px;
            display: flex;
            align-items: center;
            color: $theme-text-color-2;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            user-select: none;
            transition: color 0.2s;

            &:hover:not(.shop) {
              cursor: pointer;
              color: $theme-text-color-1;

              .icon {
                transform: opacity 0.2s;
                opacity: 1;

                path {
                  transition: fill 0.2s;
                  fill: $theme-fill-color-15;
                }
              }
            }

            &.vehicle {
              width: 400px;
              max-width: 35%;
              min-width: 25%;

              &:has(~ .shop) {
                display: none;
              }
            }

            &.customer {
              &:has(~ .shop) {
                display: none;
              }
            }

            &.vehicleCustomer {
              display: none;
              width: 400px;
              max-width: 35%;
              min-width: 25%;

              &:has(~ .shop) {
                display: flex;
              }
            }

            &.shop {
              min-width: 250px;
            }

            &:has(.visible) {
              color: $theme-text-color-1;
            }

            .icon {
              margin-left: 7px;
              margin-bottom: 1px;
              min-width: 8px;
              opacity: 0.6;

              path {
                fill: $theme-fill-color-11;
              }

              &.visible {
                opacity: 1;

                path {
                  fill: $theme-fill-color-15;
                }
              }

              &.asc {
                transform: rotate(180deg);
              }
            }
          }
        }

        .row {
          display: flex;
          align-items: center;
          border-bottom: 1px solid $theme-border-color-4;

          &:has(.cell.shop) {
            min-width: 1000px;
          }

          .cell {
            width: 25%;
            padding: 10px 24px;
            align-items: center;
            color: $theme-text-color-1;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            user-select: none;

            &.vehicle {
              display: flex;
              min-height: 64px;
              width: 400px;
              max-width: 35%;
              min-width: 25%;
              cursor: pointer;

              &:hover {
                .name {
                  color: $theme-text-color-19;
                }
              }

              .icon {
                position: relative;
                margin-right: 16px;
                width: 44px;
                height: 44px;
                flex-shrink: 0;
                background-position: center left 3px;
                background-size: 180%;
                background-repeat: no-repeat;
                background-color: $theme-background-color-13;
                border-radius: 50%;
                border: 1px solid $theme-border-color-2;

                .check {
                  width: 32px;
                  height: 32px;
                  right: -10px;
                  top: -6px;
                }
              }

              .name {
                color: $theme-text-color-0;
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                transition: color 0.2s ease-out;
              }

              .customer {
                display: none;
                overflow: hidden;
                text-wrap: nowrap;
                text-overflow: ellipsis;
              }

              &:has(~ .shop) {
                .name {
                  overflow: hidden;
                  text-wrap: nowrap;
                  text-overflow: ellipsis;
                }

                .customer {
                  display: block;
                  margin-top: 4px;
                  color: $theme-text-color-2;
                  font-family: Lato;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px;
                }
              }
            }

            &.customer {
              &:has(~ .shop) {
                display: none;
              }
            }

            &.shop {
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              overflow: hidden;
              font-family: Lato;
              font-style: normal;
              line-height: 16px;
              min-width: 250px;

              .name {
                width: 100%;
                color: $theme-text-color-0;
                font-size: 14px;
                font-weight: 700;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .address {
                width: 100%;
                margin-top: 4px;
                color: $theme-text-color-2;
                font-size: 12px;
                font-weight: 400;
                overflow: hidden;
                text-overflow: ellipsis;
                text-wrap: nowrap;
              }
            }
          }

          .arrow,
          .cellMobile {
            display: none;
          }
        }
      }

      .button {
        margin: auto;
        width: fit-content;
        padding: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $theme-text-color-19;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        cursor: pointer;
        user-select: none;

        .icon {
          margin-left: 8px;
          transform: rotate(90deg);

          path {
            fill: $theme-fill-color-12;
          }
        }

        &:hover {
          color: $theme-text-color-8;

          .icon path {
            fill: $theme-fill-color-2;
          }
        }

        &:active {
          color: $theme-text-color-7;

          .icon path {
            fill: $theme-fill-color-1;
          }
        }
      }

      .noData {
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
          margin: 40px auto 20px;
          height: 160px;
          width: 160px;
        }

        .text {
          margin-bottom: 64px;
          color: $theme-text-color-4;
          text-align: center;
          font-family: Lato;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: ($lg-1 - 1px)) {
  .wrapper {
    padding: 24px;

    .section {
      .summary {
        gap: 16px;
      }

      .vehicles {
        .table {
          .tableHeader {
            .cell {
              &:not(.vehicle) {
                padding: 10px 12px;
              }

              &.vehicleCustomer {
                min-width: 300px;
              }
            }
          }

          .row {
            .cell {
              &:not(.vehicle) {
                padding: 10px 12px;
              }

              &.vehicle:has(~ .shop) {
                min-width: 300px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: ($lg - 1px)) {
  .wrapper {
    .section {
      .summary {
        flex-wrap: wrap;

        .card {
          flex-basis: 35%;
        }

        .divider {
          &:nth-child(5) {
            background-color: $theme-background-color-13;
          }

          &.additional {
            display: block;
            height: 1px;
            flex-basis: 35%;
            flex-grow: 1;
          }
        }
      }

      .vehicles {
        .table {
          .tableHeader {
            &:has(.cell.shop) {
              min-width: 1000px;
            }
            .cell {
              &.vehicle {
                min-width: 35%;
              }

              &.customer {
                display: none;
              }

              &.vehicleCustomer {
                min-width: 30%;
              }

              &.shop {
                min-width: 20%;
              }
            }
          }

          .row {
            &:has(.cell.shop) {
              min-width: 1000px;
            }
            .cell {
              &.vehicle {
                min-width: 35%;

                &:has(~ .shop) {
                  min-width: 30%;
                }

                .name {
                  overflow: hidden;
                  text-wrap: nowrap;
                  text-overflow: ellipsis;
                }

                .customer {
                  display: block;
                  margin-top: 4px;
                  color: $theme-text-color-2;
                  font-family: Lato;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px;
                }
              }

              &.customer {
                display: none;
              }

              &.shop {
                min-width: 20%;
              }
            }
          }
        }
      }

      .services {
        .table {
          .tableHeader {
            &:has(.cell.shop) {
              min-width: 940px;
            }

            .cell {
              &.shop {
                min-width: 220px;
              }
            }
          }

          .row {
            &:has(.cell.shop) {
              min-width: 940px;
            }

            .cell {
              &.shop {
                min-width: 220px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: ($md-1 - 1px)) {
  .wrapper {
    padding: 24px 16px;

    .section {
      .vehicles {
        .header {
          .title {
            max-width: 350px;
          }
        }

        .table {
          .tableHeader {
            &:has(.cell.shop) {
              min-width: 940px;
            }

            .cell {
              &.vehicleCustomer {
                min-width: 25%;
              }
            }
          }

          .row {
            &:has(.cell.shop) {
              min-width: 940px;
            }

            .cell {
              &.vehicle {
                &:has(~ .shop) {
                  min-width: 25%;
                }
              }
            }
          }
        }
      }

      .services {
        .table {
          .tableHeader {
            &:has(.cell.shop) {
              min-width: 920px;
            }
          }

          .row {
            &:has(.cell.shop) {
              min-width: 920px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: ($sm - 1px)) {
  .wrapper {
    .section {
      .summary {
        .card {
          flex-basis: 100%;
        }

        .divider {
          width: 100%;
          height: 1px;

          &:nth-child(5) {
            background-color: $theme-background-color-7;
          }

          &.additional {
            display: none;
          }
        }
      }

      .vehicles {
        .header {
          flex-wrap: wrap;

          &:not(:has(~ .noData)) {
            border-bottom: 1px solid $theme-border-color-4;
          }

          .icon {
            display: none;
          }

          .title {
            margin: 0;
            max-width: initial;
          }

          .counter {
            width: 100%;
            margin-top: 16px;
            justify-content: center;
          }
        }

        .table {
          .tableHeader {
            display: none;
          }

          .row {
            position: relative;
            flex-wrap: wrap;

            &:has(.cell.shop) {
              min-width: unset;
            }

            &.expanded {
              border-radius: 8px;
              box-shadow: 2px 10px 50px 0px #3f4b8021;
              border-bottom: none;

              .arrow {
                background-image: url(../../assets/icons/arrow-blue-up.svg);
              }

              .cellMobile {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-basis: 100%;
                max-width: 100%;
                padding: 20px 24px;
                user-select: none;
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;

                .parameter {
                  color: $theme-text-color-2;
                  padding-right: 20px;
                  flex-shrink: 0;
                }

                .value {
                  display: flex;
                  align-items: center;
                  text-align: end;
                  color: $theme-text-color-1;
                }

                .shop {
                  max-width: calc(100% - 87px);
                  text-align: end;
                  font-family: Lato;
                  font-style: normal;
                  line-height: 16px;

                  .name {
                    width: 100%;
                    color: $theme-text-color-1;
                    font-size: 14px;
                    font-weight: 400;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }

                  .address {
                    width: 100%;
                    margin-top: 4px;
                    color: $theme-text-color-2;
                    font-size: 12px;
                    font-weight: 400;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-wrap: nowrap;
                  }
                }
              }
            }

            .cell {
              display: none;

              &.vehicle {
                display: flex;
                margin-right: 64px;
                padding-right: 6px;
                width: 100%;

                .customer {
                  display: none !important;
                }

                &:hover {
                  .name {
                    color: $theme-text-color-0;
                  }
                }
              }
            }

            .arrow {
              display: block;
              position: absolute;
              top: 0;
              right: 0;
              padding: 32px;
              background-image: url(../../assets/icons/arrow-gray-down.svg);
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }
      }

      .services {
        .header {
          &:not(:has(~ .noData)) {
            border-bottom: 1px solid $theme-border-color-4;
          }

          .icon {
            display: none;
          }
        }

        .table {
          .tableHeader {
            display: none;
          }

          .row {
            position: relative;
            flex-wrap: wrap;

            &:has(.cell.shop) {
              min-width: unset;
            }

            &.expanded {
              border-radius: 8px;
              box-shadow: 2px 10px 50px 0px #3f4b8021;
              border-bottom: none;

              .arrow {
                background-image: url(../../assets/icons/arrow-blue-up.svg);
              }

              .cellMobile {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-basis: 100%;
                max-width: 100%;
                padding: 20px 24px;
                user-select: none;
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;

                .parameter {
                  color: $theme-text-color-2;
                  padding-right: 20px;
                  flex-shrink: 0;
                }

                .value {
                  color: $theme-text-color-1;
                  display: block;
                  text-align: end;
                }

                .shop {
                  max-width: calc(100% - 87px);
                  text-align: end;
                  font-family: Lato;
                  font-style: normal;
                  line-height: 16px;

                  .name {
                    width: 100%;
                    color: $theme-text-color-1;
                    font-size: 14px;
                    font-weight: 400;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }

                  .address {
                    width: 100%;
                    margin-top: 4px;
                    color: $theme-text-color-2;
                    font-size: 12px;
                    font-weight: 400;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-wrap: nowrap;
                  }
                }
              }
            }

            .cell {
              display: none;

              &.vehicle {
                display: flex;
                margin-right: 64px;
                padding-right: 6px;
                max-width: initial;
                width: 100%;

                &:hover {
                  .name {
                    color: $theme-text-color-0;
                  }
                }

                .name {
                  overflow: hidden;
                  text-wrap: nowrap;
                  text-overflow: ellipsis;
                }

                .customer {
                  display: none !important;
                }
              }
            }

            .arrow {
              display: block;
              position: absolute;
              top: 0;
              right: 0;
              padding: 32px;
              background-image: url(../../assets/icons/arrow-gray-down.svg);
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }
      }
    }
  }
}
